import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Rating,
  Divider,
  Link,
  ListItemIcon
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryIcon from '@mui/icons-material/Category';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import styled from '@emotion/styled';
import LandingPageData from './LandingPageData';
import { styled as muiStyled } from '@mui/material/styles';

const importancePriority = {
  'Critical': 3,
  'Important': 2,
  'Nice to have': 1
};

const sortByImportance = (a, b) => {
  return importancePriority[b.importance] - importancePriority[a.importance];
};

const CompanyBanner = muiStyled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
}));

const CompanyOverviewCard = muiStyled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.paper,
}));

const CompanyLogo = muiStyled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `4px solid ${theme.palette.background.paper}`,
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  top: -50,
  left: theme.spacing(3),
  boxShadow: theme.shadows[2],
}));

const SkillCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

const SkillHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1.5),
}));

const SkillHeading = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  border: `1px solid ${theme.palette.divider}`,
}));

const CourseCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.2s ease-in-out',
  position: 'relative',
  overflow: 'visible', // Allow badge to overflow
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));



const AnalysisResults = ({ results }) => {
  if (!results?.data?.company_analysis) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>No results available</Typography>
      </Box>
    );
  }

  const { company_analysis } = results.data;
  
  // First, create skill categories from required_skills
  const organizedCourses = {
    technical: company_analysis.required_skills?.technical?.map(skill => ({
      skill: skill.skill,
      importance: skill.importance,
      description: skill.description,
      workforce_percentage: skill.workforce_percentage,
      proficiency_level: skill.proficiency_level,
      courses: []
    })) || [],
    domain: company_analysis.required_skills?.domain?.map(skill => ({
      skill: skill.knowledge,
      importance: skill.importance,
      description: skill.description,
      workforce_percentage: skill.workforce_percentage,
      proficiency_level: skill.proficiency_level,
      courses: []
    })) || [],
    soft: company_analysis.required_skills?.soft?.map(skill => ({
      skill: skill.skill,
      importance: skill.importance,
      description: skill.description,
      workforce_percentage: skill.workforce_percentage,
      proficiency_level: skill.proficiency_level,
      courses: []
    })) || []
  };

  // Organize courses using the new skill_matched and skill_category fields
  if (results.data.recommended_courses) {
    results.data.recommended_courses.forEach(course => {
      const category = course.skill_category?.toLowerCase(); // technical, soft, or domain
      const matchedSkill = course.skill_matched;

      if (category && matchedSkill && organizedCourses[category]) {
        // Find the matching skill in our organized structure
        const skillEntry = organizedCourses[category].find(s => 
          s.skill.toLowerCase() === matchedSkill.toLowerCase()
        );

        if (skillEntry) {
          skillEntry.courses.push(course);
        }
      }
    });
  }

  // Destructure from the new data structure
  const { 
    scraped_data,
    metadata,
    recommendations
  } = results.data;

  // Get the summary and mission statement from company_analysis
  const summary = company_analysis?.analysis_text;
  const mission_statement = company_analysis?.mission_statement;
  
  // Get LinkedIn data
  const companyData = scraped_data?.linkedin_data;
  const companySize = companyData?.company_size_on_linkedin;
  const companyLogo = companyData?.profile_pic_url;
  const coverImage = companyData?.background_cover_image_url;
  const companyType = companyData?.company_type?.toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const companyExtra = companyData?.extra;
  const foundingDate = companyExtra?.founding_date;
  const companyAge = foundingDate ? new Date().getFullYear() - foundingDate.year : null;
  const headquarters = companyData?.hq;
  const fundingRounds = companyData?.funding_data;
  const totalFunding = fundingRounds?.reduce((sum, round) => sum + (round.money_raised || 0), 0);

  // Convert the organized courses object to arrays for rendering
  const organizedCoursesArray = {
    technical: Object.values(organizedCourses.technical),
    soft: Object.values(organizedCourses.soft),
    domain: Object.values(organizedCourses.domain)
  };

  return (
    <Box>
      {/* Company Overview Section */}
      <Box sx={{ mb: 4 }}>
        {/* Company Banner and Logo */}
        <CompanyBanner>
          <img
            src={companyData?.background_cover_image_url}
            alt="Company Banner"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </CompanyBanner>

        <CompanyOverviewCard>
          <CompanyLogo
            src={companyData?.profile_pic_url}
            alt={companyData?.name}
          />
          <Box sx={{ pt: 4 }}>
            <Typography variant="h4" gutterBottom>
              {companyData?.name || 'Company Name'}
            </Typography>
            <Typography 
              variant="subtitle1" 
              color="text.secondary"
              sx={{ fontStyle: 'italic', mb: 2 }}
            >
              {companyData?.tagline}
            </Typography>
            
            {/* Company Details */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {companyData?.company_size_on_linkedin && (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PeopleIcon color="action" />
                    <Typography>{companyData.company_size_on_linkedin} employees</Typography>
                  </Box>
                </Grid>
              )}
              
              {companyData?.hq && (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LocationOnIcon color="action" />
                    <Typography>{`${companyData.hq.city}, ${companyData.hq.country}`}</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>

            {/* Specialties */}
            {companyData?.specialities && companyData.specialities.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" gutterBottom>Key Specialties</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {companyData.specialities.map((specialty, index) => (
                    <Chip key={index} label={specialty} size="small" />
                  ))}
                </Box>
              </Box>
            )}

            <Typography variant="body1" sx={{ mb: 3 }}>
              {company_analysis?.analysis_text}
            </Typography>

            {company_analysis?.mission_statement && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Mission Statement
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    fontStyle: 'italic',
                    color: 'text.secondary'
                  }}
                >
                  {company_analysis.mission_statement}
                </Typography>
              </Box>
            )}

            {/* Industry Focus */}
            {company_analysis.industry_focus?.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Industry Focus
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {company_analysis.industry_focus.map((industry, index) => (
                    <Chip
                      key={index}
                      label={industry}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Box>
            )}

            {/* Key Specialties */}
            {company_analysis.key_specialities?.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Key Specialties
                </Typography>
                <Grid container spacing={1}>
                  {company_analysis.key_specialities.map((specialty, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={specialty}
                        color="secondary"
                        variant="outlined"
                        sx={{ 
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 1.5
                          }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {/* Key Roles */}
            {company_analysis.key_roles?.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Key Roles
                </Typography>
                <List>
                  {company_analysis.key_roles.map((role, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <WorkIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={role.department}
                        secondary={role.description}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        </CompanyOverviewCard>
      </Box>

      {/* Skills and Categories */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Skills & Categories Mapping</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Object.entries(organizedCourses).map(([category, skills]) => (
            skills.length > 0 && (
              <Box key={category} sx={{ mb: 4 }}>
                <Typography 
                  variant="h6" 
                  color="primary" 
                  sx={{ 
                    mb: 2,
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  {category === 'technical' && <WorkIcon />}
                  {category === 'domain' && <BusinessCenterIcon />}
                  {category === 'soft' && <PeopleIcon />}
                  {category.charAt(0).toUpperCase() + category.slice(1)} Skills
                </Typography>

                {skills.map((skillData, index) => (
                  <SkillCard key={index}>
                    <SkillHeader>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                        <Typography variant="h6">
                          {skillData.skill}
                        </Typography>
                        <Chip 
                          label={skillData.importance}
                          color={
                            skillData.importance === 'Critical' ? 'error' :
                            skillData.importance === 'Important' ? 'warning' : 'default'
                          }
                          size="small"
                        />
                       
                          <Chip 
                            label={`${skillData.workforce_percentage}% of workforce`}
                            variant="outlined"
                            size="small"
                          />
                     
                        {skillData.proficiency_level && (
                          <Chip 
                            label={skillData.proficiency_level}
                            color="info"
                            size="small"
                          />
                        )}
                      </Box>
                      
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ mb: 3 }}
                      >
                        {skillData.description}
                      </Typography>
                    </SkillHeader>
                  </SkillCard>
                ))}
              </Box>
            )
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Recommended Courses */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Recommended Courses</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Technical Skills Courses */}
          {organizedCoursesArray.technical.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography 
                variant="h5" 
                color="primary" 
                sx={{ 
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontWeight: 600
                }}
              >
                <WorkIcon />
                Technical Skills
              </Typography>
              
              {organizedCoursesArray.technical.map(({ skill, importance, courses }) => (
                <Box key={skill} sx={{ mb: 4 }}>
                  <SkillHeading>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                        {skill}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {courses.length} course{courses.length !== 1 ? 's' : ''} available
                      </Typography>
                    </Box>
                    <Chip 
                      label={importance}
                      size="medium"
                      color={
                        importance === 'Critical' ? 'error' :
                        importance === 'Important' ? 'warning' : 'default'
                      }
                      sx={{ 
                        fontWeight: 500,
                        px: 2,
                        height: 32
                      }}
                    />
                  </SkillHeading>

                  <Grid container spacing={3}>
                    {courses.map((course, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <CourseCard>
                          {/* Relevance Score Badge */}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 16,
                              right: 16,
                              backgroundColor: (theme) => {
                                const score = course.relevance_score;
                                if (score >= 90) return '#1b5e20';
                                if (score >= 80) return '#2e7d32';
                                if (score >= 70) return '#388e3c';
                                if (score >= 60) return '#ffa000';
                                if (score >= 50) return '#f57c00';
                                if (score >= 40) return '#e65100';
                                return '#c62828';
                              },
                              color: '#ffffff',
                              borderRadius: '12px',
                              px: 1.5,
                              py: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              zIndex: 1,
                              boxShadow: 1,
                            }}
                          >
                            <StarIcon sx={{ fontSize: '0.875rem' }} />
                            {Math.round(course.relevance_score)}%
                          </Box>

                          <CardContent sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', mb: 2.5 }}>
                              <Avatar 
                                src={course.educationProviderLogo} 
                                alt={course.educationProviderName}
                                sx={{ 
                                  width: 56, 
                                  height: 56, 
                                  mr: 2,
                                  border: '2px solid',
                                  borderColor: 'background.paper',
                                  boxShadow: 1
                                }}
                              />
                              <Box sx={{ flex: 1 }}>
                                <Link 
                                  href={course.url} 
                                  target="_blank"
                                  sx={{ 
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    display: 'block',
                                    '&:hover': {
                                      color: 'primary.main',
                                    }
                                  }}
                                >
                                  <Typography 
                                    variant="h6" 
                                    sx={{ 
                                      fontWeight: 600,
                                      fontSize: '1.1rem',
                                      lineHeight: 1.3,
                                      mb: 0.5,
                                      display: '-webkit-box',
                                      overflow: 'hidden',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 2,
                                      pr: 8,
                                      maxWidth: 'calc(100% - 1px)',
                                    }}
                                  >
                                    {course.course_name}
                                  </Typography>
                                </Link>
                                <Typography 
                                  variant="subtitle2" 
                                  color="text.secondary"
                                  sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5
                                  }}
                                >
                                  <SchoolIcon sx={{ fontSize: '1rem' }} />
                                  {course.educationProviderName}
                                </Typography>
                              </Box>
                            </Box>

                            <Box 
                              sx={{ 
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                mb: 2.5
                              }}
                            >
                              {course.cost !== null && (
                                <Chip
                                  size="small"
                                  label={course.cost === 0 ? 'Free' : `${course.cost?.toLocaleString()} ${course.currency}`}
                                  color={course.cost === 0 ? 'success' : 'primary'}
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              )}
                              {course.start_date && (
                                <Chip
                                  size="small"
                                  label={`Starts: ${course.start_date}`}
                                  color="secondary"
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              )}
                            </Box>

                            <Typography 
                              variant="body2"
                              sx={{ 
                                backgroundColor: 'grey.50',
                                p: 2,
                                borderRadius: 2,
                                lineHeight: 1.6,
                                color: 'text.primary',
                                fontStyle: 'italic'
                              }}
                            >
                              {course.match_reason}
                            </Typography>
                          </CardContent>
                        </CourseCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          )}

          {/* Soft Skills Courses */}
          {organizedCoursesArray.soft.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography 
                variant="h5" 
                color="primary" 
                sx={{ 
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontWeight: 600
                }}
              >
                <PeopleIcon />
                Soft Skills
              </Typography>
              
              {organizedCoursesArray.soft.map(({ skill, importance, courses }) => (
                <Box key={skill} sx={{ mb: 4 }}>
                  <SkillHeading>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                        {skill}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {courses.length} course{courses.length !== 1 ? 's' : ''} available
                      </Typography>
                    </Box>
                    <Chip 
                      label={importance}
                      size="medium"
                      color={
                        importance === 'Critical' ? 'error' :
                        importance === 'Important' ? 'warning' : 'default'
                      }
                      sx={{ 
                        fontWeight: 500,
                        px: 2,
                        height: 32
                      }}
                    />
                  </SkillHeading>

                  <Grid container spacing={3}>
                    {courses.map((course, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <CourseCard>
                          {/* Relevance Score Badge */}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 16,
                              right: 16,
                              backgroundColor: (theme) => {
                                const score = course.relevance_score;
                                if (score >= 90) return '#1b5e20';
                                if (score >= 80) return '#2e7d32';
                                if (score >= 70) return '#388e3c';
                                if (score >= 60) return '#ffa000';
                                if (score >= 50) return '#f57c00';
                                if (score >= 40) return '#e65100';
                                return '#c62828';
                              },
                              color: '#ffffff',
                              borderRadius: '12px',
                              px: 1.5,
                              py: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              zIndex: 1,
                              boxShadow: 1,
                            }}
                          >
                            <StarIcon sx={{ fontSize: '0.875rem' }} />
                            {Math.round(course.relevance_score)}%
                          </Box>

                          <CardContent sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', mb: 2.5 }}>
                              <Avatar 
                                src={course.educationProviderLogo} 
                                alt={course.educationProviderName}
                                sx={{ 
                                  width: 56, 
                                  height: 56, 
                                  mr: 2,
                                  border: '2px solid',
                                  borderColor: 'background.paper',
                                  boxShadow: 1
                                }}
                              />
                              <Box sx={{ flex: 1 }}>
                                <Link 
                                  href={course.url} 
                                  target="_blank"
                                  sx={{ 
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    display: 'block',
                                    '&:hover': {
                                      color: 'primary.main',
                                    }
                                  }}
                                >
                                  <Typography 
                                    variant="h6" 
                                    sx={{ 
                                      fontWeight: 600,
                                      fontSize: '1.1rem',
                                      lineHeight: 1.3,
                                      mb: 0.5,
                                      display: '-webkit-box',
                                      overflow: 'hidden',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 2,
                                      pr: 8,
                                      maxWidth: 'calc(100% - 64px)',
                                    }}
                                  >
                                    {course.course_name}
                                  </Typography>
                                </Link>
                                <Typography 
                                  variant="subtitle2" 
                                  color="text.secondary"
                                  sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5
                                  }}
                                >
                                  <SchoolIcon sx={{ fontSize: '1rem' }} />
                                  {course.educationProviderName}
                                </Typography>
                              </Box>
                            </Box>

                            <Box 
                              sx={{ 
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                mb: 2.5
                              }}
                            >
                              {course.cost !== null && (
                                <Chip
                                  size="small"
                                  label={course.cost === 0 ? 'Free' : `${course.cost?.toLocaleString()} ${course.currency}`}
                                  color={course.cost === 0 ? 'success' : 'primary'}
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              )}
                              {course.start_date && (
                                <Chip
                                  size="small"
                                  label={`Starts: ${course.start_date}`}
                                  color="secondary"
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              )}
                            </Box>

                            <Typography 
                              variant="body2"
                              sx={{ 
                                backgroundColor: 'grey.50',
                                p: 2,
                                borderRadius: 2,
                                lineHeight: 1.6,
                                color: 'text.primary',
                                fontStyle: 'italic'
                              }}
                            >
                              {course.match_reason}
                            </Typography>
                          </CardContent>
                        </CourseCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          )}

          {/* Domain Expertise Courses */}
          {organizedCoursesArray.domain.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography 
                variant="h5" 
                color="primary" 
                sx={{ 
                  mb: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontWeight: 600
                }}
              >
                <BusinessCenterIcon />
                Domain Expertise
              </Typography>
              
              {organizedCoursesArray.domain.map(({ skill, importance, courses }) => (
                <Box key={skill} sx={{ mb: 4 }}>
                  <SkillHeading>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                        {skill}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {courses.length} course{courses.length !== 1 ? 's' : ''} available
                      </Typography>
                    </Box>
                    <Chip 
                      label={importance}
                      size="medium"
                      color={
                        importance === 'Critical' ? 'error' :
                        importance === 'Important' ? 'warning' : 'default'
                      }
                      sx={{ 
                        fontWeight: 500,
                        px: 2,
                        height: 32
                      }}
                    />
                  </SkillHeading>

                  <Grid container spacing={3}>
                    {courses.map((course, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <CourseCard>
                          {/* Relevance Score Badge */}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 16,
                              right: 16,
                              backgroundColor: (theme) => {
                                const score = course.relevance_score;
                                if (score >= 90) return '#1b5e20';
                                if (score >= 80) return '#2e7d32';
                                if (score >= 70) return '#388e3c';
                                if (score >= 60) return '#ffa000';
                                if (score >= 50) return '#f57c00';
                                if (score >= 40) return '#e65100';
                                return '#c62828';
                              },
                              color: '#ffffff',
                              borderRadius: '12px',
                              px: 1.5,
                              py: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              zIndex: 1,
                              boxShadow: 1,
                            }}
                          >
                            <StarIcon sx={{ fontSize: '0.875rem' }} />
                            {Math.round(course.relevance_score)}%
                          </Box>

                          <CardContent sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', mb: 2.5 }}>
                              <Avatar 
                                src={course.educationProviderLogo} 
                                alt={course.educationProviderName}
                                sx={{ 
                                  width: 56, 
                                  height: 56, 
                                  mr: 2,
                                  border: '2px solid',
                                  borderColor: 'background.paper',
                                  boxShadow: 1
                                }}
                              />
                              <Box sx={{ flex: 1 }}>
                                <Link 
                                  href={course.url} 
                                  target="_blank"
                                  sx={{ 
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    display: 'block',
                                    '&:hover': {
                                      color: 'primary.main',
                                    }
                                  }}
                                >
                                  <Typography 
                                    variant="h6" 
                                    sx={{ 
                                      fontWeight: 600,
                                      fontSize: '1.1rem',
                                      lineHeight: 1.3,
                                      mb: 0.5,
                                      display: '-webkit-box',
                                      overflow: 'hidden',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 2,
                                      pr: 8,
                                      maxWidth: 'calc(100% - 64px)',
                                    }}
                                  >
                                    {course.course_name}
                                  </Typography>
                                </Link>
                                <Typography 
                                  variant="subtitle2" 
                                  color="text.secondary"
                                  sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5
                                  }}
                                >
                                  <SchoolIcon sx={{ fontSize: '1rem' }} />
                                  {course.educationProviderName}
                                </Typography>
                              </Box>
                            </Box>

                            <Box 
                              sx={{ 
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                mb: 2.5
                              }}
                            >
                              {course.cost !== null && (
                                <Chip
                                  size="small"
                                  label={course.cost === 0 ? 'Free' : `${course.cost?.toLocaleString()} ${course.currency}`}
                                  color={course.cost === 0 ? 'success' : 'primary'}
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              )}
                              {course.start_date && (
                                <Chip
                                  size="small"
                                  label={`Starts: ${course.start_date}`}
                                  color="secondary"
                                  variant="outlined"
                                  sx={{ fontWeight: 500 }}
                                />
                              )}
                            </Box>

                            <Typography 
                              variant="body2"
                              sx={{ 
                                backgroundColor: 'grey.50',
                                p: 2,
                                borderRadius: 2,
                                lineHeight: 1.6,
                                color: 'text.primary',
                                fontStyle: 'italic'
                              }}
                            >
                              {course.match_reason}
                            </Typography>
                          </CardContent>
                        </CourseCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      
      {/* Add the LandingPageData component at the end */}
      <LandingPageData 
        results={results} 
        partnerId="c8980386-a0b8-4d4a-a1b3-eedc6e1240ef"
        // partnerId="1ac69c4a-b402-4eba-ab9d-3894c254dad7" 
      />
    </Box>
  );
};

export default AnalysisResults; 