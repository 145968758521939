import React from 'react';
import { Container, Typography } from '@mui/material';
import ISATermsManager from '../../components_mydra/ISATermsManager';

const ISAPage = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        ISA Terms Management
      </Typography>
      <ISATermsManager />
    </Container>
  );
};

export default ISAPage;
