import { getAuth } from 'firebase/auth';

// Add debugging for environment variables
console.log('Environment Variables:', {
  COURSE_MATCH_AI_API_BASE_URL: process.env.REACT_APP_COURSE_MATCH_AI_API_BASE_URL,
  NODE_ENV: process.env.NODE_ENV
});

const COURSE_MATCH_AI_API_BASE_URL = process.env.REACT_APP_COURSE_MATCH_AI_API_BASE_URL || 'http://192.168.1.166:8081'; // Add fallback
// const COURSE_MATCH_AI_API_BASE_URL = 'http://192.168.1.166:8080';

export const analyzeCourseMatch = async (formData, onProgress) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const url = `${COURSE_MATCH_AI_API_BASE_URL}/analyze`;
    console.log('Making request to:', url);

    // Start the analysis
    const response = await fetch(url, {
      method: 'POST',
    //   credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin,
        'X-Environment': 'Production'
      },
      mode: 'cors',
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ error: 'Failed to parse error response' }));
      console.error('Error response:', errorData);
      throw new Error(errorData.error || 'Failed to analyze company');
    }

    // For now, let's return the direct response until the progress endpoint is ready
    const data = await response.json();
    console.log('Success response:', data);
    
    // Simulate progress for now
    const steps = [0, 1, 2, 3, 4, 5];
    for (const step of steps) {
      onProgress(step);
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    return data;

    /* Uncomment this when progress endpoint is ready
    // Start polling for progress
    const pollProgress = async () => {
      const progressResponse = await fetch(`${COURSE_MATCH_AI_API_BASE_URL}/analyze/progress`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!progressResponse.ok) {
        throw new Error('Failed to get analysis progress');
      }

      const progressData = await progressResponse.json();
      
      if (onProgress && progressData.currentStep !== undefined) {
        onProgress(progressData.currentStep);
      }

      if (!progressData.isComplete) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        return pollProgress();
      }

      return progressData.results;
    };

    return await pollProgress();
    */

  } catch (error) {
    console.error('Error analyzing company:', error);
    if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
      throw new Error('Unable to connect to the analysis service. Please check your connection or try again later.');
    }
    throw error;
  }
}; 

export const getAnalysesList = async (limit = 50, offset = 0, environment = null) => {
  try {
    console.log('Fetching analyses list with params:', { limit, offset, environment });
    
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    console.log('Authentication token obtained');

    const params = new URLSearchParams({
      limit: limit.toString(),
      offset: offset.toString(),
      ...(environment && { environment })
    });
    
    const url = `${COURSE_MATCH_AI_API_BASE_URL}/analyses?${params}`;
    console.log('Making request to:', url);
    
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    console.log('Response status:', response.status);
    
    if (!response.ok) {
      console.error('Failed response:', {
        status: response.status,
        statusText: response.statusText
      });
      throw new Error('Failed to fetch analyses');
    }

    const responseData = await response.json();
    console.log('Successfully fetched analyses:', {
      data: responseData.data,
      count: responseData.data?.length,
      firstItem: responseData.data?.[0]
    });
    
    // Return the data array from the response
    return responseData.data || [];
  } catch (error) {
    console.error('Error fetching analyses:', error);
    throw error;
  }
};

export const getAnalysisDetails = async (analysisId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await fetch(
      `${COURSE_MATCH_AI_API_BASE_URL}/analyses/${analysisId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch analysis details');
    }

    const data = await response.json();
    console.log('Analysis details response:', data);
    return data;
  } catch (error) {
    console.error('Error fetching analysis details:', error);
    throw error;
  }
}; 