import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { 
    Container, 
    Typography, 
    Switch, 
    Box, 
    CircularProgress, 
    Button,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    TextField,
    IconButton, 
    Select,
    MenuItem,
    FormControl,
    Drawer,
    Divider,
    Grid,
    Link,
    InputLabel,
    FormHelperText, 
    Stack,
    LinearProgress,
    Chip,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import CompanyUsers from '../../components_mydra/CompanyUsers/CompanyUsers';


const LogoPreview = ({ url, size = 40 }) => {
    const [error, setError] = useState(false);

    if (!url || error) return null;

    return (
        <img
            src={url}
            alt="Logo"
            onError={() => setError(true)}
            style={{
                width: size,
                objectFit: 'contain'
            }}
        />
    );
};

const MydraCompaniesPage = () => {
    const [companies, setCompanies] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isEditingInDrawer, setIsEditingInDrawer] = useState(false);
    const [editedCompanyDataInDrawer, setEditedCompanyDataInDrawer] = useState({});

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false);
    const [companyToVerify, setCompanyToVerify] = useState(null);
    const [isVerifying, setIsVerifying] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [landingPages, setLandingPages] = useState([]);
    const [isLoadingLandingPages, setIsLoadingLandingPages] = useState(false);
    const [isCreatingLandingPage, setIsCreatingLandingPage] = useState(false);
    const [landingPageCreationTime, setLandingPageCreationTime] = useState(null);
    const [pollingInterval, setPollingInterval] = useState(null);

    const [isEditingLandingPage, setIsEditingLandingPage] = useState(false);

    const [analysisId, setAnalysisId] = useState(null);
    const [analysisStatus, setAnalysisStatus] = useState(null);
    const [nextPollTime, setNextPollTime] = useState(null);
    const POLL_INTERVAL = 20000; // 20 seconds

    const [analysisProgress, setAnalysisProgress] = useState(null);
    const [analysisError, setAnalysisError] = useState(null);

    // Add this state for the countdown
    const [countdown, setCountdown] = useState(null);

    const handleRowClick = (company) => {
        setSelectedCompany(company);
        setDrawerOpen(true);
        // Fetch landing pages when company is selected
        fetchLandingPages(company._id);
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
        setSelectedCompany(null);
        
        // Clear landing page related states
        setLandingPages([]);
        setIsLoadingLandingPages(false);
        setIsCreatingLandingPage(false);
        setLandingPageCreationTime(null);
        setIsEditingLandingPage(false);
        
        // Clear analysis related states
        setAnalysisId(null);
        setAnalysisStatus(null);
        setAnalysisProgress(null);
        setAnalysisError(null);
        setNextPollTime(null);
        setCountdown(null);
        
        // Clear polling interval if exists
        if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
        }
    };

    const handleEditInDrawer = () => {
        setIsEditingInDrawer(true);
        setEditedCompanyDataInDrawer({ ...selectedCompany });
    };

    const handleCancelEditInDrawer = () => {
        setIsEditingInDrawer(false);
        setEditedCompanyDataInDrawer({});
    };

    const handleInputChangeInDrawer = (e, field) => {
        // Special handling for linkedInUrl to ensure correct casing
        const fieldName = field === 'linkedinUrl' ? 'linkedInUrl' : field;
        setEditedCompanyDataInDrawer({ ...editedCompanyDataInDrawer, [fieldName]: e.target.value });
    };

    const handleSaveCompanyInDrawer = async () => {
        setIsSaving(true);
        try {
            const dataToSend = {
                ...editedCompanyDataInDrawer,
                tier: Number(editedCompanyDataInDrawer.tier)
            };

            const response = await fetch(`${API_BASE_URL}/mydra/companies/${selectedCompany._id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.ok) {
                setCompanies(companies.map(company => 
                    company._id === selectedCompany._id ? { ...company, ...dataToSend } : company
                ));
                setSelectedCompany({ ...selectedCompany, ...dataToSend });
                showSnackbar('Company updated successfully', 'success');
                setIsEditingInDrawer(false);
            } else {
                const errorData = await response.json();
                console.log('Error updating company:', errorData);
                throw new Error(`Failed to update company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error updating company:', error);
            console.log('Error message:', error.message);
            console.log('Error stack:', error.stack);
            showSnackbar(`Failed to update company: ${error.message}`, 'error');
        } finally {
            setIsSaving(false);
        }
    };

    const tierOptions = [
        { value: '1', label: '1 - Premium' },
        { value: '2', label: '2 - Standard' },
        { value: '3', label: '3 - Basic' },
    ];
    
    const getTierLabel = (value) => {
        let mappedValue;
        switch(value) {
            case 1:
            case '1':
                mappedValue = '1';
                break;
            case 2:
            case '2':
                mappedValue = '2';
                break;
            case 3:
            case '3':
                mappedValue = '3';
                break;
            default:
                mappedValue = null;
        }
        const tier = tierOptions.find(option => option.value === String(mappedValue));
        return tier ? tier.label : 'Unknown';
    };

    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const [newCompanyData, setNewCompanyData] = useState({
        name: '',
        legalName: '',
        tier: '',
        vat: '',
        address: '',
        domain: '',
        logoUrl: '',
    });

    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!newCompanyData.name.trim()) {
            errors.name = 'Company Name is required';
        }
        if (!newCompanyData.legalName.trim()) {
            errors.legalName = 'Legal Name is required';
        }
        if (!newCompanyData.tier) {
            errors.tier = 'Tier is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleAddCompany = async () => {
        if (!validateForm()) {
            return;
        }
    
        setIsAddingCompany(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/companies`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCompanyData),
            });
    
            if (response.ok) {
                const createdCompany = await response.json();
                showSnackbar('Company created successfully', 'success');
                fetchCompanies(); // Refetch the list of companies
                setNewCompanyData({
                    name: '',
                    legalName: '',
                    tier: '',
                    vat: '',
                    address: '',
                    domain: '',
                    logoUrl: '',
                });
                setFormErrors({});
                setAddCompanyDialogOpen(false); // Close the dialog after successful creation
            } else {
                const errorData = await response.json();
                throw new Error(`Failed to create company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error creating company:', error);
            showSnackbar(`Failed to create company: ${error.message}`, 'error');
        } finally {
            setIsAddingCompany(false);
        }
    };
    


    const handleNewCompanyInputChange = (e) => {
        const { name, value } = e.target;
        setNewCompanyData(prevData => ({
            ...prevData,
            [name]: value
        }));
        // Clear the error for this field when the user starts typing
        if (formErrors[name]) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        }
    };


    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const openConfirmDialog = (company) => {
        setCompanyToVerify(company);
        setConfirmDialogOpen(true);
    };
    
    const closeConfirmDialog = () => {
        setCompanyToVerify(null);
        setConfirmDialogOpen(false);
    };


    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken && userEmail) {
            console.log('Fetching companies');
            fetchCompanies();
        }
    }, [authToken, userEmail, isProduction]);

    const fetchCompanies = async () => {
        try {
            setIsLoadingCompanies(true);
            const response = await fetch(`${API_BASE_URL}/mydra/companies`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            const responseData = await response.json();
            console.log('Companies:', responseData);
            setCompanies(responseData);
        } catch (error) {
            console.error('Error fetching companies:', error);
            showSnackbar('Failed to fetch companies', 'error');
        } finally {
            setIsLoadingCompanies(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

   
    const handleVerifyCompany = async () => {
        if (!companyToVerify) return;
    
        setIsVerifying(true);    
        try {
            const updateData = {
                verified: true,
                name: companyToVerify.name,
                legalName: companyToVerify.legalName,
                tier: companyToVerify.tier,
                vat: companyToVerify.vat,
                address: companyToVerify.address,
                domain: companyToVerify.domain,
                taxBenefitsProvider: companyToVerify.taxBenefitsProvider,
                bankName: companyToVerify.bankName,
                iban: companyToVerify.iban
            };
    
            // Remove undefined fields
            Object.keys(updateData).forEach(key => updateData[key] === undefined && delete updateData[key]);
    
            const response = await fetch(`${API_BASE_URL}/mydra/companies/${companyToVerify._id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
    
            if (response.ok) {
                setCompanies(companies.map(company => 
                    company._id === companyToVerify._id ? { ...company, ...updateData } : company
                ));
                showSnackbar('Company verified and updated successfully', 'success');
                fetchCompanies(); // Refresh the company list
            } else {
                const errorData = await response.json();
                throw new Error(`Failed to verify company: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error verifying company:', error);
            showSnackbar(`Failed to verify company: ${error.message}`, 'error');
        } finally {
            setCompanyToVerify(null);
            setConfirmDialogOpen(false);
            setIsVerifying(false);
        }
    };


    const renderHeaderCell = (label, tooltip = null) => (
        <TableCell 
          key={label}
          sx={{
            fontWeight: 600,
            color: '#1a237e',
            fontSize: '0.9rem',
            padding: '14px 20px',
            letterSpacing: '0.3px',
          }}
        >
          {label}
          {tooltip && (
            <Tooltip
              title={
                <React.Fragment>
                  {Array.isArray(tooltip) ? (
                    tooltip.map((line, index) => (
                      <Typography key={index} variant="body2">
                        {line}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">{tooltip}</Typography>
                  )}
                </React.Fragment>
              }
              arrow
              placement="top"
            >
              <InfoIcon 
                fontSize="small" 
                sx={{ 
                  marginLeft: 1, 
                  verticalAlign: 'middle', 
                  cursor: 'help',
                  color: '#3f51b5',
                }} 
              />
            </Tooltip>
          )}
        </TableCell>
      );


    const headerCells = [
        { label: '#', tooltip: null },
        { label: 'Logo', tooltip: null },
        { label: 'Name', tooltip: null },
        { label: 'Legal Name', tooltip: null },
        { label: 'VAT number', tooltip: null},
        { label: 'Code', tooltip: null },
        { label: 'Domain', tooltip: null },
        { label: 'Tax Benefits Provider', tooltip: null },
        { label: 'Tier', tooltip: [
            'How does the tier impact Fixpay?',
            '• Tier 1: Maximum loan amount €10k',
            '• Tier 2: Maximum loan amount €7k',
            '• Tier 3: Maximum loan amount €3k'
          ]},
        { label: 'Verification Status', tooltip: null },
        { label: 'Created At', tooltip: null },
        { label: 'Actions', tooltip: null },
      ];

    // Add this new state for the landing page dialog
    const [landingPageDialogOpen, setLandingPageDialogOpen] = useState(false);
    const [landingPageData, setLandingPageData] = useState({
        linkedin_url: '',
        company_url: '',
    });

    // Add this function to handle both create and update
    const handleLandingPageSubmit = async () => {
        setIsCreatingLandingPage(true);
        const isUpdate = landingPages?.length > 0;
        
        try {
            console.log('Starting landing page analysis with params:', {
                linkedin_url: editedCompanyDataInDrawer?.linkedInUrl,
                company_url: editedCompanyDataInDrawer?.websiteUrl,
                environment: isProduction ? 'Production' : 'Staging',
                partner_mydra_id: selectedCompany?._id,
                action_type: isUpdate ? 'update' : 'create',
                ...(isUpdate && { landing_page_id: landingPages[0]._id }),
            });
            const COURSE_MATCH_API = process.env.REACT_APP_COURSE_MATCH_AI_API_BASE_URL;


            const response = await fetch(`${COURSE_MATCH_API}/start-analysis`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                },
                body: JSON.stringify({
                    linkedin_url: editedCompanyDataInDrawer?.linkedInUrl,
                    company_url: editedCompanyDataInDrawer?.websiteUrl,
                    environment: isProduction ? 'Production' : 'Staging',
                    partner_mydra_id: selectedCompany?._id,
                    action_type: isUpdate ? 'update' : 'create',
                    ...(isUpdate && { landing_page_id: landingPages[0]._id }),
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Landing page analysis response:', data);

            if (data.success && data.data.analysis_id) {
                setAnalysisId(data.data.analysis_id);
                setAnalysisStatus('pending');
                
                // Start polling
                if (pollingInterval) {
                    clearInterval(pollingInterval);
                }
                
                // Initial check
                await checkAnalysisStatus(data.data.analysis_id);
                
                // Set up polling
                const interval = setInterval(() => {
                    checkAnalysisStatus(data.data.analysis_id);
                    setNextPollTime(Date.now() + POLL_INTERVAL);
                }, POLL_INTERVAL);
                
                setPollingInterval(interval);
                setNextPollTime(Date.now() + POLL_INTERVAL);
            }

            // Update the selectedCompany with new values
            setSelectedCompany({
                ...selectedCompany,
                linkedInUrl: editedCompanyDataInDrawer.linkedInUrl,
                websiteUrl: editedCompanyDataInDrawer.websiteUrl
            });

            showSnackbar(
                isUpdate ? 'Landing page update started' : 'Landing page creation started',
                'success'
            );
            setLandingPageDialogOpen(false);
            setLandingPageCreationTime(new Date());

        } catch (error) {
            console.error('Error starting landing page analysis:', error);
            showSnackbar(
                `Failed to ${isUpdate ? 'update' : 'create'} landing page`,
                'error'
            );
        } finally {
            setIsCreatingLandingPage(false);
        }
    };

    const handleCreateLandingPage = () => {
        if (!selectedCompany) return; // Safety check

        setEditedCompanyDataInDrawer({
            ...selectedCompany,
            linkedInUrl: selectedCompany.linkedInUrl || '', // Add LinkedIn URL
            websiteUrl: selectedCompany.domain ? `https://${selectedCompany.domain}` : ''
        });
        setLandingPageDialogOpen(true);
    };

    const fetchLandingPages = async (companyId) => {
        console.log('Fetching landing pages for company:', companyId, 'in environment:', isProduction ? 'Production' : 'Staging');
        setIsLoadingLandingPages(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/companies/${companyId}/landing-pages`,
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'X-User-Email': userEmail,
                        'X-Environment': isProduction ? 'Production' : 'Staging',
                    },
                }
            );
            const data = await response.json();
            console.log('Fetched landing pages:', data);
            setLandingPages(data);
        } catch (error) {
            console.error('Error fetching landing pages:', error);
            showSnackbar('Failed to fetch landing pages', 'error');
        } finally {
            setIsLoadingLandingPages(false);
        }
    };

    // Add cleanup for polling interval
    useEffect(() => {
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [pollingInterval]);

    // Modify the drawer content to fetch landing pages when opened
    useEffect(() => {
        if (drawerOpen && selectedCompany) {
            fetchLandingPages(selectedCompany._id);
        }
    }, [drawerOpen, selectedCompany]);

    // Helper function to get base URL
    const getBaseUrl = () => {
        return isProduction 
            ? 'https://marketplace.mydra.io' 
            : 'https://marketplace.staging.mydra.io';
    };

    // Helper function to get landing page URL
    const getLandingPageUrl = (page) => {
        const baseUrl = getBaseUrl();
        return `${baseUrl}/landing-pages/${page._id}`;
    };

    // Add this new function to handle viewing the landing page
    const handleViewLandingPage = () => {
        if (landingPages && landingPages.length > 0) {
            const landingPage = landingPages[0];
            window.open(getLandingPageUrl(landingPage), '_blank');
        }
    };

    // Add this new function to handle landing page action (no new state needed)
    const handleLandingPageAction = (page = null) => {
        // If page is provided, we're updating that specific page
        // If page is null, we're creating a new landing page
        setIsEditingLandingPage(!!page);
        setLandingPageDialogOpen(true);
        
        // Pre-fill the form with existing data
        setEditedCompanyDataInDrawer({
            ...selectedCompany, // This includes all company data
            linkedInUrl: selectedCompany.linkedInUrl || '', // Use linkedInUrl from company data
            // Format the domain as a proper URL if it exists
            websiteUrl: selectedCompany.domain ? `https://${selectedCompany.domain}` : '',
            landingPageId: page?._id // Store the ID of the page being updated if it exists
        });
    };

    // Add this function to handle dialog close
    const handleLandingPageDialogClose = () => {
        setLandingPageDialogOpen(false);
    };

    // Update the isPollingStatus function to include all states that need polling
    const isPollingStatus = (status) => 
        status === 'pending' || 
        status === 'analyzing' || 
        status === 'processing_landing_page';

    // Update the checkAnalysisStatus function to handle the new steps and statuses
    const checkAnalysisStatus = async (analysisId) => {
        try {
            const COURSE_MATCH_API = process.env.REACT_APP_COURSE_MATCH_AI_API_BASE_URL;
            console.log('Checking analysis status:', analysisId);
            
            const response = await fetch(
                `${COURSE_MATCH_API}/analyses/${analysisId}/status`,
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'X-User-Email': userEmail,
                        'X-Environment': isProduction ? 'Production' : 'Staging',
                    },
                }
            );

            const data = await response.json();
            console.log('Analysis status response:', data);

            if (data.success) {
                setAnalysisStatus(data.data.status);
                setAnalysisProgress(data.data.progress);
                
                // Only stop polling when status is 'completed' or 'failed'
                if (data.data.status === 'completed' || data.data.status === 'failed') {
                    if (pollingInterval) {
                        clearInterval(pollingInterval);
                        setPollingInterval(null);
                    }
                    setNextPollTime(null);
                    setCountdown(null);

                    // If completed, refresh the landing pages
                    if (data.data.status === 'completed') {
                        await fetchLandingPages(selectedCompany._id);
                    }
                }
                
                if (data.data.status === 'failed') {
                    setAnalysisError(data.data.error);
                }
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            console.error('Error checking analysis status:', error);
            setAnalysisError('Failed to check analysis status');
            
            // Clear polling on error
            if (pollingInterval) {
                clearInterval(pollingInterval);
                setPollingInterval(null);
            }
            setNextPollTime(null);
            setCountdown(null);
        }
    };

    // Update the useEffect for countdown to trigger the check when it reaches 0
    useEffect(() => {
        let timer;
        
        // Only start polling if we have an analysisId and status is pending or analyzing
        if (analysisId && isPollingStatus(analysisStatus) && nextPollTime) {
            // Update countdown immediately
            const remaining = Math.max(0, Math.floor((nextPollTime - Date.now()) / 1000));
            setCountdown(remaining);
            
            timer = setInterval(() => {
                const remaining = Math.max(0, Math.floor((nextPollTime - Date.now()) / 1000));
                setCountdown(remaining);
                
                if (remaining === 0) {
                    checkAnalysisStatus(analysisId);
                    setNextPollTime(Date.now() + POLL_INTERVAL);
                }
            }, 1000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [nextPollTime, analysisId, analysisStatus]);

    // Add this helper function to get the step display name
    const getStepDisplayName = (step) => {
        const displayNames = {
            'linkedin_scraped': 'LinkedIn Data Scraped',
            'skills_matching': 'Skills Matched',
            'course_recommendations': 'Course Recommendations Generated',
            'landing_page_started': 'Landing Page Creation Started',
            'landing_page_completed': 'Landing Page Creation Completed'
        };
        return displayNames[step] || step;
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>

            <Container maxWidth={false} sx={{ mt: 2, px: { xs: 1, sm: 2, md: 3 } }}>
                <Typography variant="h4" align="center" sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
                    Companies
                </Typography>

                {/* <Box display="flex" alignItems="center" mb={2} ml={2}>
                    <Typography variant="body1">Staging</Typography>
                    <Switch
                        checked={isProduction}
                        onChange={toggleEnvironment}
                        inputProps={{ 'aria-label': 'Environment toggle' }}
                    />
                    <Typography variant="body1">Production</Typography>
                </Box> */}

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1">Staging</Typography>
                        <Switch
                            checked={isProduction}
                            onChange={toggleEnvironment}
                            inputProps={{ 'aria-label': 'Environment toggle' }}
                        />
                        <Typography variant="body1">Production</Typography>
                    </Box>
                    
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setAddCompanyDialogOpen(true)}
                    >
                        Add Company
                    </Button>
                </Box>
                
                {isProduction && (
                    <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                        Warning: You are in Production mode. Changes may impact live data.
                    </Typography>
                )}
    
                {authToken && userEmail ? (
                    isLoadingCompanies ? (
                        <Box display="flex" justifyContent="center" mt={5}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>      
                            <TableHead>
                                <TableRow sx={{
                                    backgroundColor: '#f0f4f8',
                                    borderBottom: '2px solid #3f51b5',
                                    fontWeight: 'bold',
                                }}>
                                    {headerCells.map(cell => renderHeaderCell(cell.label, cell.tooltip))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {companies.map((company, index) => (
                                        <TableRow 
                                            key={company._id}
                                            onClick={() => handleRowClick(company)}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                            }}
                                        >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                <LogoPreview url={company.logoUrl} />
                                            </TableCell>
                                            <TableCell>{company.name}</TableCell>
                                            <TableCell>{company.legalName}</TableCell>
                                            <TableCell>{company.vat}</TableCell>
                                            <TableCell>{company.code}</TableCell>
                                            <TableCell>{company.domain}</TableCell>
                                            <TableCell>{company.taxBenefitsProvider}</TableCell>
                                            <TableCell>{getTierLabel(company.tier)}</TableCell>
                                            <TableCell>
                                                {company.verified ? (
                                                    <Tooltip title="Verified">
                                                        <Box display="flex" alignItems="center">
                                                            <CheckCircleIcon color="success" />
                                                            <Typography variant="body2" sx={{ ml: 1 }}>Verified</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Pending Verification">
                                                        <Box display="flex" alignItems="center">
                                                            <PendingIcon color="warning" />
                                                            <Typography variant="body2" sx={{ ml: 1 }}>Pending</Typography>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell>{new Date(company.createdAt).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                {!company.verified && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openConfirmDialog(company);
                                                        }}
                                                        size="small"
                                                    >
                                                        Verify
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    )
                ) : (
                    <Typography variant="body1">Please log in to view the companies.</Typography>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                
                {/* Verify company dialog */}
                <Dialog
                    open={confirmDialogOpen}
                    onClose={closeConfirmDialog}
                    aria-labelledby="verify-dialog-title"
                    aria-describedby="verify-dialog-description"
                >
                    <DialogTitle id="verify-dialog-title">{"Confirm Company Verification"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="verify-dialog-description">
                            Are you sure you want to verify the company "{companyToVerify?.name}"?
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog} color="primary" disabled={isVerifying}>
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleVerifyCompany} 
                            color="primary" 
                            autoFocus 
                            disabled={isVerifying}
                        >
                            {isVerifying ? 'Verifying...' : 'Verify'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={confirmDialogOpen}
                    onClose={closeConfirmDialog}
                    aria-labelledby="verify-dialog-title"
                    aria-describedby="verify-dialog-description"
                >
                    <DialogTitle id="verify-dialog-title">{"Confirm Company Verification"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="verify-dialog-description">
                            Are you sure you want to verify the company "{companyToVerify?.name}"?
                            This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog} color="primary" disabled={isVerifying}>
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleVerifyCompany} 
                            color="primary" 
                            autoFocus 
                            disabled={isVerifying}
                        >
                            {isVerifying ? 'Verifying...' : 'Verify'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Company Dialog */}
                <Dialog open={addCompanyDialogOpen} onClose={() => setAddCompanyDialogOpen(false)}>
                    <DialogTitle>Add New Company</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="Company Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.name}
                            onChange={handleNewCompanyInputChange}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            required
                        />
                        <TextField
                            margin="dense"
                            name="legalName"
                            label="Legal Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.legalName}
                            onChange={handleNewCompanyInputChange}
                            error={!!formErrors.legalName}
                            helperText={formErrors.legalName}
                            required
                        />
                        <FormControl fullWidth margin="dense" error={!!formErrors.tier} required>
                            <InputLabel id="tier-select-label">Tier</InputLabel>
                            <Select
                                labelId="tier-select-label"
                                name="tier"
                                value={newCompanyData.tier}
                                onChange={handleNewCompanyInputChange}
                            >
                                <MenuItem value={1}>1 - Premium</MenuItem>
                                <MenuItem value={2}>2 - Standard</MenuItem>
                                <MenuItem value={3}>3 - Basic</MenuItem>
                            </Select>
                            {formErrors.tier && <FormHelperText>{formErrors.tier}</FormHelperText>}
                        </FormControl>
                        <TextField
                            margin="dense"
                            name="vat"
                            label="VAT Number"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.vat}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="address"
                            label="Address"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.address}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="domain"
                            label="Domain"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.domain}
                            onChange={handleNewCompanyInputChange}
                        />
                        <TextField
                            margin="dense"
                            name="logoUrl"
                            label="Logo URL"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newCompanyData.logoUrl}
                            onChange={handleNewCompanyInputChange}
                        />
                        {newCompanyData.logoUrl && (
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                <LogoPreview url={newCompanyData.logoUrl} size={80} />
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddCompanyDialogOpen(false)}>Cancel</Button>
                        <Button 
                            onClick={handleAddCompany} 
                            variant="contained" 
                            color="primary"
                            disabled={isAddingCompany || !newCompanyData.name || !newCompanyData.legalName || !newCompanyData.tier}
                        >
                            {isAddingCompany ? 'Creating...' : 'Create Company'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </Container>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: { width: { xs: '100%', sm: 900 } }
                }}
            >
          
                <Box sx={{ p: 2 }}>
                    {selectedCompany && (
                        <>
                            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                                Company Details
                                {!isEditingInDrawer ? (
                                    <Button
                                        startIcon={<EditIcon />}
                                        onClick={handleEditInDrawer}
                                        sx={{ ml: 2 }}
                                    >
                                        Edit
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            startIcon={<SaveIcon />}
                                            onClick={handleSaveCompanyInDrawer}
                                            sx={{ ml: 2 }}
                                            disabled={isSaving}
                                        >
                                            {isSaving ? 'Saving...' : 'Save'}
                                        </Button>
                                        <Button
                                            startIcon={<CancelIcon />}
                                            onClick={handleCancelEditInDrawer}
                                            sx={{ ml: 2 }}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                )}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Name</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.name || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'name')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.name}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Domain</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.domain || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'domain')}
                                        />
                                    ) : (
                                        selectedCompany.domain ? (
                                            <Link 
                                                href={`https://${selectedCompany.domain}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                sx={{ 
                                                    color: 'primary.main',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {selectedCompany.domain}
                                            </Link>
                                        ) : (
                                            <Typography>N/A</Typography>
                                        )
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Code</Typography>
                                    <Typography>{selectedCompany.code}</Typography>
                                </Grid>

                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Legal Name</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.legalName || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'legalName')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.legalName || 'N/A'}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>VAT Number</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.vat || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'vat')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.vat || 'N/A'}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Address</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.address || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'address')}
                                        />
                                    ) : (
                                        <Typography>{selectedCompany.address || 'N/A'}</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Tier</Typography>
                                    {isEditingInDrawer ? (
                                        <FormControl fullWidth>
                                            <InputLabel id="tier-select-label">Tier</InputLabel>
                                            <Select
                                                labelId="tier-select-label"
                                                value={editedCompanyDataInDrawer.tier || ''}
                                                onChange={(e) => handleInputChangeInDrawer(e, 'tier')}
                                            >
                                                <MenuItem value={1}>1 - Premium</MenuItem>
                                                <MenuItem value={2}>2 - Standard</MenuItem>
                                                <MenuItem value={3}>3 - Basic</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography>{getTierLabel(selectedCompany.tier)}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Verification Status</Typography>
                                    <Box display="flex" alignItems="center">
                                        {selectedCompany.verified ? (
                                            <>
                                                <CheckCircleIcon color="success" fontSize="small" />
                                                <Typography variant="body2" sx={{ ml: 1 }}>Verified</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <PendingIcon color="warning" fontSize="small" />
                                                <Typography variant="body2" sx={{ ml: 1 }}>Pending</Typography>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Created At</Typography>
                                    <Typography>{new Date(selectedCompany.createdAt).toLocaleDateString()}</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>Tax Benefits Provider</Typography>
                                    <Typography>{selectedCompany.taxBenefitsProvider || 'N/A'}</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="subtitle2" sx={{ mr: 2, minWidth: 'fit-content' }}>Logo</Typography>
                                    {isEditingInDrawer ? (
                                        <Box sx={{ flex: 1 }}>
                                            <TextField
                                                fullWidth
                                                value={editedCompanyDataInDrawer.logoUrl || ''}
                                                onChange={(e) => handleInputChangeInDrawer(e, 'logoUrl')}
                                                label="Logo URL"
                                            />
                                            {editedCompanyDataInDrawer.logoUrl && (
                                                <Box sx={{ mt: 1 }}>
                                                    <LogoPreview url={editedCompanyDataInDrawer.logoUrl} size={80} />
                                                </Box>
                                            )}
                                        </Box>
                                    ) : (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LogoPreview url={selectedCompany.logoUrl} size={120} />
                                        </Box>
                                    )}
                                </Grid>

                                {/* LinkedIn URL field */}
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>LinkedIn URL</Typography>
                                    {isEditingInDrawer ? (
                                        <TextField
                                            fullWidth
                                            value={editedCompanyDataInDrawer.linkedInUrl || ''}
                                            onChange={(e) => handleInputChangeInDrawer(e, 'linkedInUrl')}
                                        />
                                    ) : (
                                        selectedCompany.linkedInUrl ? (
                                            <Link 
                                                href={selectedCompany.linkedInUrl}
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                sx={{ 
                                                    color: 'primary.main',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {selectedCompany.linkedInUrl}
                                            </Link>
                                        ) : (
                                            <Typography>N/A</Typography>
                                        )
                                    )}
                                </Grid>

                                <Grid item xs={12} sx={{ mb: 4 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Landing Page
                                    </Typography>
                                    <Stack direction="row" spacing={2} mb={2}>
                                        {/* Only show Create button if no landing pages exist */}
                                        {landingPages?.length === 0 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleLandingPageAction}
                                                startIcon={<EditIcon />}
                                                disabled={analysisStatus === 'pending' || analysisStatus === 'analyzing'}
                                            >
                                                CREATE LANDING PAGE
                                            </Button>
                                        )}
                                    </Stack>

                                    {/* Analysis Status Box */}
                                    {analysisId && analysisStatus && analysisProgress && (
                                        <Box sx={{ 
                                            p: 3, 
                                            border: 1, 
                                            borderColor: 'divider', 
                                            borderRadius: 1,
                                            bgcolor: 'background.paper'
                                        }}>
                                            {/* Status section */}
                                            <Box sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                gap: 1,
                                                mb: 3  // Add margin bottom for spacing
                                            }}>
                                                {analysisStatus === 'completed' ? (
                                                    <CheckCircleOutlineIcon color="success" />
                                                ) : (
                                                    <CircularProgress size={20} />
                                                )}
                                                <Typography variant="subtitle1" color="text.primary">
                                                    Status: {analysisStatus.replace(/_/g, ' ').charAt(0).toUpperCase() + analysisStatus.slice(1)}
                                                </Typography>
                                            </Box>

                                            {/* Divider between status and steps */}
                                            <Divider sx={{ my: 2 }} />

                                            {/* Steps progress section */}
                                            <Stack spacing={1.5}>  {/* Increased spacing between steps */}
                                                {['linkedin_scraped', 'skills_matching', 'course_recommendations', 'landing_page_started', 'landing_page_completed'].map((step) => {
                                                    const isCompleted = analysisProgress.steps_completed?.includes(step);
                                                    const isCurrentStep = analysisProgress.current_step === step;
                                                    
                                                    return (
                                                        <Box
                                                            key={step}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 1.5,  // Increased gap between icon and text
                                                                color: isCompleted ? 'success.main' : 'text.secondary',
                                                                fontSize: '0.875rem',
                                                                pl: 1  // Add some padding left
                                                            }}
                                                        >
                                                            {isCompleted ? (
                                                                <CheckCircleOutlineIcon color="success" sx={{ fontSize: 20 }} />
                                                            ) : isCurrentStep ? (
                                                                <CircularProgress size={16} color="primary" />
                                                            ) : (
                                                                <PendingIcon sx={{ fontSize: 20, color: 'action.disabled' }} />
                                                            )}
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    fontWeight: isCurrentStep ? 500 : 400,
                                                                    color: isCurrentStep ? 'primary.main' : isCompleted ? 'success.main' : 'text.secondary'
                                                                }}
                                                            >
                                                                {getStepDisplayName(step)}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}
                                            </Stack>

                                            {/* Progress chip */}
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'flex-end',
                                                mt: 3  // Increased top margin
                                            }}>
                                                <Chip
                                                    label={`${analysisProgress.steps_completed?.length || 0}/5 steps completed`}
                                                    size="small"
                                                    color={analysisProgress.steps_completed?.length === 5 ? "success" : "default"}
                                                    sx={{ fontSize: '0.75rem' }}
                                                />
                                            </Box>

                                            {/* Next update countdown */}
                                            {analysisStatus !== 'completed' && nextPollTime && (
                                                <Typography 
                                                    variant="caption" 
                                                    color="text.secondary"
                                                    sx={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: 0.5,
                                                        mt: 2,
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <UpdateIcon sx={{ fontSize: 14 }} />
                                                    Next update in: {Math.max(0, Math.floor((nextPollTime - Date.now()) / 1000))}s
                                                </Typography>
                                            )}
                                        </Box>
                                    )}

                                    {analysisError && (
                                        <Alert severity="error" sx={{ mt: 2 }}>
                                            {analysisError}
                                        </Alert>
                                    )}

                                    {/* Updated Landing Pages table */}
                                    {landingPages?.length > 0 && (
                                        <Box sx={{ mt: 4 }}>
                                            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                                                Landing Pages
                                            </Typography>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Language</TableCell>
                                                            <TableCell>ID</TableCell>
                                                            <TableCell>Last Updated</TableCell>
                                                            <TableCell align="right">Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {landingPages.map((page) => (
                                                            <TableRow key={page._id}>
                                                                <TableCell>
                                                                    <Chip 
                                                                        label={page.language.toUpperCase()} 
                                                                        size="small"
                                                                        color="primary"
                                                                        variant="outlined"
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                                                        {page._id}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {new Date(page.updatedAt).toLocaleDateString()}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                                                                        {page.language.toLowerCase() === 'en' && (
                                                                            <Button
                                                                                variant="outlined"
                                                                                size="small"
                                                                                startIcon={<EditIcon />}
                                                                                onClick={() => handleLandingPageAction(page)}
                                                                                disabled={analysisStatus === 'pending' || analysisStatus === 'analyzing'}
                                                                            >
                                                                                Update
                                                                            </Button>
                                                                        )}
                                                                        <Button
                                                                            variant="outlined"
                                                                            size="small"
                                                                            startIcon={<LaunchIcon />}
                                                                            onClick={() => window.open(getLandingPageUrl(page), '_blank')}
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    )}

                                </Grid>
                            </Grid>

                            <Divider sx={{ my: 2 }} />
                            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                                Company Users
                            </Typography>
                            <CompanyUsers
                                companyId={selectedCompany._id}
                                authToken={authToken}
                                userEmail={userEmail}
                                isProduction={isProduction}
                            />

                            
                        </>
                    )}
                </Box>
            </Drawer>

            {/* Update the existing Landing Page Dialog */}
            <Dialog
                open={landingPageDialogOpen}
                onClose={() => setLandingPageDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {landingPages.length > 0 ? 'Update Landing Page' : 'Create Landing Page'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2 }}>
                        <TextField
                            fullWidth
                            label="Company LinkedIn URL"
                            variant="outlined"
                            margin="normal"
                            value={editedCompanyDataInDrawer?.linkedInUrl || ''}
                            onChange={(e) => setEditedCompanyDataInDrawer({
                                ...editedCompanyDataInDrawer,
                                linkedInUrl: e.target.value
                            })}
                        />
                        <TextField
                            fullWidth
                            label="Company Website URL"
                            variant="outlined"
                            margin="normal"
                            value={editedCompanyDataInDrawer?.websiteUrl || ''}
                            onChange={(e) => setEditedCompanyDataInDrawer({
                                ...editedCompanyDataInDrawer,
                                websiteUrl: e.target.value
                            })}
                        />
                        <FormHelperText>
                            Please verify the LinkedIn and website URLs before proceeding
                        </FormHelperText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLandingPageDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={handleLandingPageSubmit}
                        disabled={isCreatingLandingPage || !editedCompanyDataInDrawer?.linkedInUrl || !editedCompanyDataInDrawer?.websiteUrl}
                    >
                        {isCreatingLandingPage ? (
                            <CircularProgress size={24} />
                        ) : landingPages?.length > 0 ? (
                            'Update'
                        ) : (
                            'Create'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Box sx={{ mt: 2 }}>
                {analysisId && analysisStatus && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="body2" color="textSecondary">
                            Analysis Status: {analysisStatus}
                        </Typography>
                        {analysisStatus !== 'completed' && nextPollTime && (
                            <Typography variant="body2" color="textSecondary">
                                Next check in: {Math.max(0, Math.floor((nextPollTime - Date.now()) / 1000))}s
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>

        </Box>
    );
};

export default MydraCompaniesPage;