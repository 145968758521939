import React from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Chip,
  IconButton,
  Tooltip,
  Pagination,
  CircularProgress,
  Link
} from '@mui/material';
import { format } from 'date-fns';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BusinessIcon from '@mui/icons-material/Business';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LaunchIcon from '@mui/icons-material/Launch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
  transition: 'all 0.2s ease-in-out',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
    borderColor: theme.palette.primary.main,
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 64,
  height: 64,
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  '& img': {
    objectFit: 'contain',
  },
}));

const RecentAnalyses = ({ analyses = [], onAnalysisSelect, page, totalPages, onPageChange, loading }) => {
  console.log('Analyses prop:', analyses);

  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: 4,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Recent Analyses
        </Typography>
        <Chip 
          label={`${analyses.length} analyses`}
          color="primary"
          size="small"
        />
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : !analyses || analyses.length === 0 ? (
        <Box 
          sx={{ 
            textAlign: 'center', 
            py: 6,
            px: 3,
            bgcolor: 'grey.50',
            borderRadius: 2,
          }}
        >
          <Typography variant="subtitle1" color="text.secondary">
            No analyses found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Start by analyzing a company above
          </Typography>
        </Box>
      ) : (
        <>
          <List sx={{ mb: 3 }}>
            {analyses.map((analysis) => (
              <StyledListItem
                key={analysis?.id || Math.random()}
                secondaryAction={
                  <Tooltip title="View Analysis">
                    <IconButton 
                      edge="end" 
                      onClick={() => analysis?.id && onAnalysisSelect(analysis.id)}
                      sx={{ 
                        bgcolor: 'primary.main',
                        color: 'white',
                        width: 48,
                        height: 48,
                        '&:hover': {
                          bgcolor: 'primary.dark',
                          transform: 'scale(1.1)',
                        },
                        transition: 'all 0.2s ease-in-out',
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ListItemAvatar>
                  <StyledAvatar 
                    src={analysis?.company_logo}
                    alt={analysis?.company_name || 'Company'}
                    variant="rounded"
                  >
                    <BusinessIcon sx={{ width: 32, height: 32, color: 'primary.main' }} />
                  </StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {analysis?.company_name || 'Unnamed Company'}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          {analysis?.environment && (
                            <Chip 
                              label={analysis.environment}
                              size="small"
                              color={analysis.environment === 'Production' ? 'primary' : 'default'}
                              sx={{ 
                                fontWeight: 500,
                                borderRadius: '8px',
                              }}
                            />
                          )}
                          {analysis?.status && (
                            <Chip 
                              label={analysis.status}
                              size="small"
                              color={
                                analysis.status === 'completed' ? 'success' :
                                analysis.status === 'failed' ? 'error' : 'warning'
                              }
                              sx={{ 
                                fontWeight: 500,
                                borderRadius: '8px',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  }
                  secondary={
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      gap: 1,
                      mt: 1,
                      '& .MuiBox-root': {
                        p: 0.5,
                        px: 1,
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      },
                    }}>
                      {analysis?.linkedin_url && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <LinkedInIcon fontSize="small" color="primary" />
                          <Link 
                            href={analysis.linkedin_url}
                            target="_blank"
                            sx={{ 
                              color: 'text.secondary',
                              textDecoration: 'none',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            {analysis.linkedin_url}
                          </Link>
                        </Box>
                      )}
                      {analysis?.company_url && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <LaunchIcon fontSize="small" color="action" />
                          <Link 
                            href={analysis.company_url}
                            target="_blank"
                            sx={{ 
                              color: 'text.secondary',
                              textDecoration: 'none',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            {analysis.company_url}
                          </Link>
                        </Box>
                      )}
                      {analysis?.created_at && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <AccessTimeIcon fontSize="small" color="action" />
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {format(new Date(analysis.created_at), 'PPpp')}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                />
              </StyledListItem>
            ))}
          </List>

          {totalPages > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination 
                count={totalPages} 
                page={page} 
                onChange={(e, value) => onPageChange(value)}
                color="primary"
                size="large"
                sx={{
                  '& .MuiPaginationItem-root': {
                    borderRadius: 1,
                  }
                }}
              />
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};

export default RecentAnalyses; 