// services/courseMatchingService.js
import { getAuth } from 'firebase/auth';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const courseMatchingService = {
    matchCourses: async (siteId, environment, educationProviderId, courses) => {
        try {
            const token = await getAuth().currentUser?.getIdToken();
            const response = await fetch(`${API_BASE_URL}/api/coursestream/course-matching/match`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    site_id: siteId,
                    environment,
                    educationProviderId,
                    courses
                })
            });

            if (!response.ok) {
                throw new Error('Failed to match courses');
            }

            const result = await response.json();
            // Return the entire result as the structure has changed
            return result;
        } catch (error) {
            console.error('Course matching error:', error);
            throw error;
        }
    }
};

