import React from 'react';
import { Drawer, List, ListItem, ListItemText, Divider, ListItemIcon, Typography, Box, Avatar, Tooltip } from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import ViewListIcon from '@mui/icons-material/ViewList';
import ScoreIcon from '@mui/icons-material/Score';
import CategoryIcon from '@mui/icons-material/Category';
import { Link } from 'react-router-dom';
import { grey, blue } from '@mui/material/colors'; // Import grey from Material-UI colors

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // For collapse button
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // For expand button
import IconButton from '@mui/material/IconButton';
import CastForEducationIcon from '@mui/icons-material/CastForEducation'; // A graduation cap icon
import SchoolIcon from '@mui/icons-material/School'; // Icon for education providers
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business'; // New import for Companies icon

import GroupAddIcon from '@mui/icons-material/GroupAdd'; // Icon for Contacts Generation
import AssignmentIcon from '@mui/icons-material/Assignment';

import Badge from '@mui/material/Badge'; // Import Badge from Material-UI

import ExtensionIcon from '@mui/icons-material/Extension'; // Can metaphorically represent fitting pieces together
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import { useTheme } from '@mui/material/styles'; // Import useTheme hook
import PaymentsIcon from '@mui/icons-material/Payments'; // Add this import at the top with other icons
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; // Add this import for Course Match AI

const Sidebar = ({ open, handleDrawerToggle }) => {
    const [user, setUser] = useState(null);
    const auth = getAuth();
    // const [open, setOpen] = useState(true); // State to manage open/collapse
    const theme = useTheme(); // Access theme for consistent styling


    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
      }, []);

      const handleSignOut = () => {
        signOut(auth)
          .then(() => {
            console.log('Sign-out successful');
          })
          .catch((error) => {
            console.error('Sign-out error', error);
          });
      }

      return (
          <Drawer
            variant="permanent"
            anchor="left"
            sx={{
              width: open ? 240 : 56, // Adjust width based on open state
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: open ? 240 : 56, // Adjust width based on open state
                boxSizing: 'border-box',
                backgroundColor: grey[50],
                overflowX: 'hidden', // Prevent horizontal scrolling
                transition: theme.transitions.create('width', { // Animate width change
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              },
            }}
          >
            <Box sx={{ height: theme.spacing(8) }}>
        {open ? (
          <>
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography
                variant="h5"
                noWrap
                component={Link}
                to="/"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  color: blue[800],
                  '&:hover': {
                    textDecoration: 'none',
                    color: blue[900],
                  },
                }}
              >
                MydraHub
              </Typography>
            </Box>
            <Divider />
          </>
        ) : (
          <Box sx={{ textAlign: 'center', p: 1 }}>
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 'bold',
                textDecoration: 'none',
                color: blue[800],
                '&:hover': {
                  textDecoration: 'none',
                  color: blue[900],
                },
              }}
            >
              M
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'fixed',
          top: '50%',
          transform: 'translateY(-50%)',
          left: open ? theme.spacing(28) : theme.spacing(5),
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            bgcolor: 'primary.light',
            '&:hover': {
              bgcolor: 'primary.main',
            },
            color: 'white',
            height: theme.spacing(4),
            width: theme.spacing(4),
            marginRight: open ? 0 : 'auto',
          }}
        >

            {open ? <ChevronLeftIcon sx={{ fontSize: '2rem' }} /> : <ChevronRightIcon sx={{ fontSize: '2rem' }} />}
              </IconButton>
            </Box>

            <List>

              <ListItem button component={Link} to="/course-stream-ai/providers">
                <Tooltip title="CourseStream AI" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <ViewListIcon />
                  </ListItemIcon>
                </Tooltip>
                {open && <ListItemText primary="CourseStream AI" />}
              </ListItem>

              {/* Add Course Match AI item */}
              <ListItem button component={Link} to="/mydra/course-match-ai">
                <Tooltip title="Course Match AI - New!" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <AutoAwesomeIcon />  {/* Or another appropriate icon */}
                  </ListItemIcon>
                </Tooltip>
                {open && (
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    '& .MuiListItemText-root': {
                      marginRight: '2px'
                    }
                  }}>
                    <ListItemText primary="Course Match AI" />
                    <Badge 
                      badgeContent="New" 
                      color="primary"
                      sx={{ 
                        '& .MuiBadge-badge': {
                          fontSize: '0.65rem',
                          height: '16px',
                          minWidth: '32px',
                          transform: 'scale(0.85) translate(35px, -14px)',
                          padding: '0 6px',
                          marginLeft: '-2px'
                        }
                      }}
                    />
                  </Box>
                )}
              </ListItem>
              {/* <ListItem button component={Link} to="/add-provider" sx={{ mt: 2 }}>
                <Tooltip title="Add Provider" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <AddBoxIcon />
                  </ListItemIcon>
                </Tooltip>
                {open && <ListItemText primary="Add Provider" />}
              </ListItem>  */}
              {/* <ListItem button component={Link} to="/providers">
                <Tooltip title="Providers" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                      <ViewListIcon />
                    </ListItemIcon>
                  </Tooltip>
                {open && <ListItemText primary="View Providers" />}

              </ListItem> */}
              <ListItem button component={Link} to="/ep-scores">
                <Tooltip title="EP Scoring" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <ScoreIcon />
                  </ListItemIcon>
                </Tooltip>
                {open && <ListItemText primary="EP Scoring" />}
              </ListItem>
            </List> 
          
          <Divider />
          <List>
            
            <ListItem button component={Link} to="/mydra/add-course">
              <Tooltip title="Mydra Add Course" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <AddBoxIcon />

                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra Add Course" />}
            </ListItem>
            <ListItem button component={Link} to="/mydra/new-courses">
              <Tooltip title="Mydra New Courses" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <NewReleasesIcon />

                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra New Courses" />}
            </ListItem>

            <ListItem button component={Link} to="/mydra/course">
              <Tooltip title="Mydra Courses" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <CastForEducationIcon />
                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra Courses"/>}
            </ListItem>

            <ListItem button component={Link} to="/mydra-categories">
              <Tooltip title="Mydra Categories" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <CategoryIcon />
                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra Categories"/>}
            </ListItem>
            <ListItem button component={Link} to="/mydra/providers">
              <Tooltip title="Mydra Providers" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <SchoolIcon />
                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra Providers"/>}
            </ListItem>
            <ListItem button component={Link} to="/mydra/tags">
              <Tooltip title="Mydra Tags" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <AssignmentIcon />
                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra Tags"/>}
            </ListItem>
            <ListItem button component={Link} to="/mydra/agreements">
              <Tooltip title="Mydra Agreements" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <AssignmentIcon />
                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Mydra Agreements"/>}
            </ListItem>
            <ListItem button component={Link} to="/mydra/isa">
              <Tooltip title="ISA Terms - New!" placement="right">
                <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                  <PaymentsIcon />
                </ListItemIcon>
              </Tooltip>
              {open && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  '& .MuiListItemText-root': {
                    marginRight: '2px' // Reduced margin
                  }
                }}>
                  <ListItemText primary="ISA Terms" />
                  <Badge 
                    badgeContent="New" 
                    color="primary"
                    sx={{ 
                      '& .MuiBadge-badge': {
                        fontSize: '0.65rem',
                        height: '16px',
                        minWidth: '32px',
                        transform: 'scale(0.85) translate(35px, -14px)',
                        padding: '0 6px',
                        marginLeft: '-2px'
                      }
                    }}
                  />
                </Box>
              )}
            </ListItem>
            {/* New Companies ListItem */}
            <ListItem button component={Link} to="/mydra/companies">
            <Tooltip title="Mydra Companies - New!" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                  <BusinessIcon />
              </ListItemIcon>
            </Tooltip>
            {/* <Badge badgeContent="New" color="primary"> */}
              {open && <ListItemText primary="Mydra Companies" secondary="Try it now!" sx={{ '.MuiListItemText-secondary': { fontSize: '0.7rem' } }} />} 
              {/* </Badge> */}
          </ListItem>

         {/* Mydra User Orders */}
         <ListItem button component={Link} to="/mydra/user-orders">
            <Tooltip title="User Orders" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
              {/* <Badge badgeContent="New" color="primary"> */}
                {open && <ListItemText primary="User Orders" secondary="Try it now!" sx={{ '.MuiListItemText-secondary': { fontSize: '0.7rem' } }} />}
              {/* </Badge>   */}
          </ListItem>

          {/* Mydra User Scoring */}
          <ListItem button component={Link} to="/mydra/user-scoring">
            <Tooltip title="User Scoring" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
              {/* <Badge badgeContent="New" color="primary"> */}
                {open && <ListItemText primary="User Scoring" secondary="Try it now!" sx={{ '.MuiListItemText-secondary': { fontSize: '0.7rem' } }} />}
              {/* </Badge> */}
          </ListItem>
        

          {/* Mydra Enquiries */}
          <ListItem button component={Link} to="/mydra/enquiries">
            <Tooltip title="Enquiries" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
              {/* <Badge badgeContent="New" color="primary"> */}
                {open && <ListItemText primary="Enquiries" secondary="Try it now!" sx={{ '.MuiListItemText-secondary': { fontSize: '0.7rem' } }} />}
              {/* </Badge> */}
          </ListItem>

          {/* Benefit providers */}
          <ListItem button component={Link} to="/mydra/benefit-providers">
            <Tooltip title="Mydra Benefit Providers" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
                {/* <Badge badgeContent="New" color="primary"> */}
                {open && <ListItemText primary="Mydra Benefit Providers" secondary="Try it now!" sx={{ '.MuiListItemText-secondary': { fontSize: '0.7rem' } }} />}
              {/* </Badge> */}
          </ListItem>

          </List>
          <Divider />
          <List>
            <ListItem button component={Link} to="/contacts">
              <Tooltip title="Contacts Automation" placement="right">
                  <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                    <GroupAddIcon />
                  </ListItemIcon>
                </Tooltip>
              {open && <ListItemText primary="Contacts Automation"  />}
            </ListItem>
          </List>
          <Divider />
          <List>
          <ListItem button component={Link} to="/analytics">
            <Tooltip title="Course Analytics" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <BarChartIcon sx={{ color: 'action.disabled' }} />
              </ListItemIcon>
            </Tooltip>
            {open && <ListItemText primary="Course Analytics" />} 
          </ListItem>

        {/* Copilot */}
          <ListItem button component={Link} to="/mydra/copilot-analytics">
            <Tooltip title="Copilot Analytics" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
              {/* <Badge badgeContent="New" color="primary">   */}
                {open && <ListItemText primary="Copilot Analytics" />}
              {/* </Badge> */}
          </ListItem> 

        {/* Course Automations Reporting */}
        <ListItem button component={Link} to="/mydra/course-automations-reporting">
            <Tooltip title="Course Auto Updates" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
              {/* <Badge badgeContent="New" color="primary">   */}
                {open && <ListItemText primary="Course Auto Updates" />}
              {/* </Badge> */}
          </ListItem> 

          {/* Course QA */}
          <ListItem button component={Link} to="/mydra/course-qa">
            <Tooltip title="Course QA" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                <AssignmentIcon />
              </ListItemIcon>
            </Tooltip>
                {/* <Badge badgeContent="New" color="primary">   */}
                {open && <ListItemText primary="Course QA" />}  
              {/* </Badge> */}
          </ListItem> 


          </List><Divider />
          <List>
          {/* // Inside your Sidebar component, find the AI Upskilling ListItem and modify it like this: */}
          <ListItem button component={Link} to="/ai-upskilling">
            <Tooltip title="AI Upskilling - Try it now!" placement="right">
              <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                {/* Wrap the Icon with Badge and set badgeContent to "New" */}
                {/* <Badge badgeContent="New" color="primary"> */}
                  <ExtensionIcon /> 
                {/* </Badge> */}
              </ListItemIcon>
            </Tooltip>
            {/* <Badge badgeContent="New" color="primary"> */}

              {open && <ListItemText primary="AI Upskilling" secondary="Try it now!" sx={{ '.MuiListItemText-secondary': { fontSize: '0.7rem' } }} />} 
            {/* </Badge> */}
          </ListItem>
          </List>
  
              {/* User Details Section */}
              {user && (
                <Box sx={{ mt: 'auto', width: '100%' }}>
                  <Divider />
                  {open ? (
                    <Box sx={{ p: 2 }}>
                      <Typography variant="overline" display="block" gutterBottom>Logged in as:</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <Avatar src={user.photoURL} alt={user.displayName} sx={{ width: 24, height: 24,  mb:1 }} />
                        <Typography variant="subtitle2" noWrap sx={{ ml: 1, mb:1 }}>
                          {user.displayName}
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body2" 
                        noWrap 
                        sx={{ 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis',
                          fontSize: '0.75rem', // Makes the font size smaller
                          color: 'grey[100]' // Sets the color to a light grey 
                        }}>
                        {user.email}
                      </Typography>
                      <IconButton onClick={handleSignOut} sx={{ mt: 1 }}>
                        <ExitToAppIcon />
                        {open && <Typography 
                          variant="body2" 
                          noWrap 
                          sx={{ 
                            ml: 0.5 
                          }}
                          >
                            Logout
                          </Typography>
                        }

                      </IconButton>
                    </Box>
                  ) : (
                    <Box sx={{ mt: 'auto', width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      
                      <Divider />
                      <Box sx={{ p: 2 }}>
                        {/* Center the avatar when sidebar is collapsed */}
                        <Tooltip title={`Logged in as ${user.displayName}`} placement="right">
                          <IconButton onClick={handleSignOut} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Avatar src={user.photoURL} alt={user.displayName} sx={{ width: 24, height: 24 }} />
                            {open && <Typography variant="body2" noWrap sx={{ ml: 0.5 }}>Logout</Typography>}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              </Drawer>
          );
      };


            
export default Sidebar;


