import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StepBox = ({ number, title, points }) => (
  <Paper 
    elevation={2}
    sx={{ 
      p: 3,
      mb: 3,
      position: 'relative',
      '&:not(:last-child)::after': {
        content: '""',
        position: 'absolute',
        bottom: -24,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 2,
        height: 24,
        bgcolor: 'primary.light',
        zIndex: 1
      }
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Box
        sx={{
          width: 32,
          height: 32,
          borderRadius: '50%',
          bgcolor: 'primary.main',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mr: 2,
          fontWeight: 'bold'
        }}
      >
        {number}
      </Box>
      <Typography variant="h6" color="primary.main">
        {title}
      </Typography>
    </Box>
    <Box sx={{ pl: 6.5 }}>
      {points.map((point, index) => (
        <Typography 
          key={index} 
          variant="body1" 
          sx={{ 
            mb: 1,
            display: 'flex',
            alignItems: 'flex-start',
            whiteSpace: 'pre-wrap',
            fontFamily: 'monospace'
          }}
        >
          {point}
        </Typography>
      ))}
    </Box>
  </Paper>
);

const HowItWorksModal = ({ open, onClose }) => {
  const steps = [
    {
      title: 'Initial Company Data Collection',
      points: [
        'User submits LinkedIn company URL',
        'System validates and normalizes the URL',
        'Fetches company profile data from ProxyCurl API',
        'Collects and analyzes website content if provided'
      ]
    },
    {
      title: 'Company Analysis',
      points: [
        'Takes cleaned company data and website content',
        'Sends to OpenAI for initial analysis',
        'Identifies industry focus and key specialties',
        'Determines company size range',
        'Returns structured data including:',
        '    • Industry focus',
        '    • Key specialties',
        '    • Company size range',
        '    • Required skills broken into three categories:',
        '        - Technical skills',
        '        - Soft skills',
        '        - Domain knowledge'
      ]
    },
    {
      title: 'Skills Analysis',
      points: [
        'For each skill category (Technical, Soft, Domain):',
        '    • Identifies specific skills needed for the company',
        '    • Determines importance level (Critical/Important/Nice to have)',
        '    • Estimates workforce percentage needing each skill',
        '    • For technical skills: adds required proficiency levels',
        '    • For domain knowledge: flags industry-specific requirements'
      ]
    },
    {
      title: 'Skills to Categories Matching',
      points: [
        'Takes each identified skill',
        'Compares against available Mydra categories',
        'Uses OpenAI to match skills with relevant categories',
        'Provides category matches with relevance scores (0-100)',
        'Includes detailed match reasoning'
      ]
    },
    {
      title: 'Course Selection & Enrichment',
      points: [
        'Retrieves courses from matched categories',
        'Analyzes course content against skill requirements',
        'Scores and selects top courses for each skill',
        'Enriches recommendations with:',
        '    • Cost information and start dates',
        '    • Education provider details',
        '    • Cohort information'
      ]
    }
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="how-it-works-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 800,
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" component="h2" color="primary.main" fontWeight="bold">
            How Course Match AI Works
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Paper 
          elevation={0}
          sx={{ 
            p: 3, 
            mb: 4, 
            bgcolor: 'primary.light', 
            color: 'primary.contrastText',
            borderRadius: 2
          }}
        >
          <Typography variant="h6" gutterBottom fontWeight="500">
            Welcome to Course Match AI
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.6 }}>
            Course Match AI conducts comprehensive company analysis to identify the essential skills required 
            by your workforce. By analyzing company profiles and technical requirements, it creates 
            personalized learning plans by matching critical skills with the most relevant courses 
            for your team's development.
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
            Follow the steps below to understand how we transform your company's profile into 
            actionable learning recommendations.
          </Typography>
        </Paper>

        {steps.map((step, index) => (
          <StepBox
            key={index}
            number={index + 1}
            title={step.title}
            points={step.points}
          />
        ))}
      </Box>
    </Modal>
  );
};

export default HowItWorksModal; 