import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import WorkIcon from '@mui/icons-material/Work';
import CategoryIcon from '@mui/icons-material/Category';
import SchoolIcon from '@mui/icons-material/School';
import StorageIcon from '@mui/icons-material/Storage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = [
  {
    label: 'LinkedIn Analysis',
    description: 'Extracting and analyzing data from LinkedIn profile',
    icon: <LinkedInIcon />
  },
  {
    label: 'Website Analysis',
    description: 'Scraping and analyzing company website content',
    icon: <LanguageIcon />
  },
  {
    label: 'Skills Generation',
    description: 'Generating relevant skills that employees need to learn',
    icon: <WorkIcon />
  },
  {
    label: 'Category Matching',
    description: 'Matching skills to Mydra platform categories',
    icon: <CategoryIcon />
  },
  {
    label: 'Course Selection',
    description: 'Finding and filtering the best matching courses',
    icon: <SchoolIcon />
  },
  {
    label: 'Data Storage',
    description: 'Storing analysis results in MydraHub database',
    icon: <StorageIcon />
  }
];

const ProcessingSteps = ({ activeStep }) => {
  const [simulatedStep, setSimulatedStep] = useState(0);

  useEffect(() => {
    let timer;
    
    // Simulate progress through steps 0-4 (Course Selection)
    if (activeStep === 0) {
      timer = setInterval(() => {
        setSimulatedStep(prev => {
          // Stop at step 4 (Course Selection)
          if (prev >= 4) {
            clearInterval(timer);
            return 4;
          }
          return prev + 1;
        });
      }, 5000); // Changed from 1500 to 3000 (3 seconds per step)
    } else {
      // When backend responds, jump to final step
      // Add a small delay before showing the final step
      setTimeout(() => {
        setSimulatedStep(5);
      }, 1000); // 1 second delay before showing completion
    }

    return () => {
      clearInterval(timer);
    };
  }, [activeStep]);

  // Use simulatedStep instead of activeStep in the render
  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4, mb: 8 }}>
      <Typography variant="h6" align="center" gutterBottom color="primary">
        Analyzing Company Data
      </Typography>
      
      <Stepper activeStep={simulatedStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={() => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {index === simulatedStep ? (
                    <CircularProgress size={24} color="primary" />
                  ) : index < simulatedStep ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    step.icon
                  )}
                </Box>
              )}
            >
              <Typography variant="subtitle1">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="body2" color="text.secondary">
                {step.description}
              </Typography>
              {index === simulatedStep && (
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={16} />
                  <Typography variant="caption" color="primary">
                    Processing...
                  </Typography>
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProcessingSteps; 