import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography,
  Box,
  Paper,
  Tab,
  Tabs,
  Button,
  Modal,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Link,
  Grid,
  Chip,
  Tooltip
} from '@mui/material';
import CompanyAnalysisForm from '../../components_mydra/CourseMatchAI/CompanyAnalysisForm';
import AnalysisResults from '../../components_mydra/CourseMatchAI/AnalysisResults';
import ProcessingSteps from '../../components_mydra/CourseMatchAI/ProcessingSteps';
import RecentAnalyses from '../../components_mydra/CourseMatchAI/RecentAnalyses';
import { analyzeCourseMatch, getAnalysesList, getAnalysisDetails } from '../../services/courseMatchService';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HowItWorksModal from '../../components_mydra/CourseMatchAI/HowItWorksModal';
import LaunchIcon from '@mui/icons-material/Launch';
import { styled } from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BusinessIcon from '@mui/icons-material/Business';
import AssessmentIcon from '@mui/icons-material/Assessment';

const ITEMS_PER_PAGE = 10;

const HeroPaper = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(145deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
  color: 'white',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
}));

const CompanyBanner = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
}));

const CompanyOverviewCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.paper,
}));

const CompanyLogo = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `4px solid ${theme.palette.background.paper}`,
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  top: -50,
  left: theme.spacing(3),
  boxShadow: theme.shadows[2],
}));

const StyledHeroSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(145deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
  color: 'white',
  padding: theme.spacing(6, 2),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  textAlign: 'center',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& .MuiTab-root': {
    minHeight: 48,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 0.5),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const CourseMatchAIPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  
  // Recent analyses state
  const [analyses, setAnalyses] = useState([]);
  const [analysesLoading, setAnalysesLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isNewAnalysis, setIsNewAnalysis] = useState(false);
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);

  // Fetch recent analyses
  const fetchAnalyses = async () => {
    setAnalysesLoading(true);
    try {
      const response = await getAnalysesList(ITEMS_PER_PAGE, (page - 1) * ITEMS_PER_PAGE);
      console.log('Fetched analyses response:', response);
      
      // Update to match the actual API response structure
      setAnalyses(response || []);
      
      // If pagination info exists in the response
      if (response.pagination) {
        setTotalPages(Math.ceil(response.pagination.total / ITEMS_PER_PAGE));
      } else {
        // Fallback to calculating based on array length
        setTotalPages(Math.ceil((response.data?.length || 0) / ITEMS_PER_PAGE));
      }
    } catch (err) {
      console.error('Error fetching analyses:', err);
      setAnalyses([]);
      setTotalPages(1);
    } finally {
      setAnalysesLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalyses();
  }, [page]);

  const handleAnalyze = async (formData) => {
    setLoading(true);
    setError(null);
    setActiveStep(0);
    setIsNewAnalysis(true);
    
    try {
      const response = await analyzeCourseMatch(formData, (step) => {
        setActiveStep(step);
      });
      
      // Structure the results the same way as getAnalysisDetails
      setResults({
        data: response
      });
      setActiveTab(1); // Switch to results tab
      fetchAnalyses();
    } catch (err) {
      setError(err.message || 'An error occurred while analyzing');
    } finally {
      setLoading(false);
      setIsNewAnalysis(false);
    }
  };

  const handleAnalysisSelect = async (analysisId) => {
    setLoading(true);
    setError(null);
    setIsNewAnalysis(false);
    try {
      const response = await getAnalysisDetails(analysisId);
      console.log('Analysis details response:', response);
      
      // Just pass the response directly - remove the transformation
      setResults(response);
      setActiveTab(1); // Switch to results tab
    } catch (err) {
      console.error('Error loading analysis details:', err);
      setError(err.message || 'Error loading analysis details');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <StyledHeroSection>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom
          sx={{ 
            fontWeight: 800,
            mb: 1,
            background: 'linear-gradient(45deg, #fff, #f0f0f0)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Course Match AI
        </Typography>

        <Typography 
          variant="h6" 
          sx={{ 
            mb: 3,
            opacity: 0.9,
            fontWeight: 300,
            letterSpacing: '0.5px',
          }}
        >
          AI-Powered Course Recommendations for Your Company
        </Typography>

        <Button
          startIcon={<HelpOutlineIcon />}
          onClick={() => setHowItWorksOpen(true)}
          variant="outlined"
          size="large"
          sx={{ 
            color: 'white',
            borderColor: 'rgba(255,255,255,0.5)',
            px: 3,
            '&:hover': {
              borderColor: 'white',
              backgroundColor: 'rgba(255,255,255,0.1)',
            }
          }}
        >
          How It Works
        </Button>

        <HowItWorksModal 
          open={howItWorksOpen} 
          onClose={() => setHowItWorksOpen(false)} 
        />
      </StyledHeroSection>

      <Paper 
        elevation={0} 
        sx={{ 
          mb: 4,
          borderRadius: 2,
          overflow: 'hidden',
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <StyledTabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
        >
          <Tab 
            label="Company Analysis" 
            icon={<BusinessIcon />} 
            iconPosition="start"
          />
          <Tab 
            label="Results" 
            icon={<AssessmentIcon />} 
            iconPosition="start"
            disabled={!results} 
          />
        </StyledTabs>

        {activeTab === 0 && (
          <Box sx={{ p: 3 }}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 4,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
              }}
            >
              <CompanyAnalysisForm onSubmit={handleAnalyze} disabled={loading} />
            </Paper>

            {loading && isNewAnalysis && (
              <Box sx={{ mt: 4 }}>
                <ProcessingSteps activeStep={activeStep} />
              </Box>
            )}

            {error && (
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  mt: 3,
                  bgcolor: 'error.light',
                  border: '1px solid',
                  borderColor: 'error.main',
                  borderRadius: 2,
                }}
              >
                <Typography color="error" variant="body1">
                  {error}
                </Typography>
              </Paper>
            )}

            <Box sx={{ mt: isNewAnalysis ? 6 : 4 }}>
              <RecentAnalyses 
                analyses={analyses}
                onAnalysisSelect={handleAnalysisSelect}
                page={page}
                totalPages={totalPages}
                onPageChange={setPage}
                loading={analysesLoading}
              />
            </Box>
          </Box>
        )}

        {activeTab === 1 && results && !loading && (
          <Box sx={{ p: 3 }}>
            <AnalysisResults results={results} />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default CourseMatchAIPage; 