import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Stack,
  CircularProgress,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Helper function to determine skill type
const determineSkillType = (course, required_skills) => {
  // Check if the course matches any technical skills
  if (required_skills?.technical?.some(skill => 
    course.match_reason.toLowerCase().includes(skill.skill.toLowerCase())
  )) {
    return 'technical';
  }
  // Check if the course matches any domain skills
  if (required_skills?.domain?.some(skill => 
    course.match_reason.toLowerCase().includes(skill.knowledge.toLowerCase())
  )) {
    return 'domain';
  }
  // Check if the course matches any soft skills
  if (required_skills?.soft?.some(skill => 
    course.match_reason.toLowerCase().includes(skill.skill.toLowerCase())
  )) {
    return 'soft';
  }
  // Default to technical if no match found
  return 'technical';
};

const LandingPageData = ({ results }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [landingPageId, setLandingPageId] = useState("df4acce5-565e-44d4-9537-6ed1cd47c721");
    const partnerId = "c8980386-a0b8-4d4a-a1b3-eedc6e1240ef";
//   const API_BASE_URL = "http://192.168.1.166:8081";
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const generateLandingPageStructure = () => {
    const companyData = results?.data?.scraped_data?.linkedin_data;
    const companyAnalysis = results?.data?.company_analysis;
    const recommendedCourses = results?.data?.recommended_courses;
    
    const companySlug = companyData?.name?.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    
    // Get top skills and roles from analysis
    const getTopSkills = () => {
      if (!companyAnalysis?.required_skills) {
        return [];
      }

      // Get critical skills from all categories
      const technicalSkills = companyAnalysis.required_skills?.technical
        ?.filter(s => s.importance === 'Critical')
        ?.map(s => s.skill) || [];
      
      const softSkills = companyAnalysis.required_skills?.soft
        ?.filter(s => s.importance === 'Critical')
        ?.map(s => s.skill) || [];
      
      const domainSkills = companyAnalysis.required_skills?.domain
        ?.filter(s => s.importance === 'Critical')
        ?.map(s => s.knowledge) || [];

      // Combine all skills and take top 3
      return [...technicalSkills, ...softSkills, ...domainSkills]
        .slice(0, 3)
        .map((skill, idx) => `#${idx + 1} ${skill}`)
        .join('\n');
    };

    const getTopJobRoles = () => {
      if (!companyAnalysis?.key_roles) {
        return [];
      }

      return companyAnalysis.key_roles
        .map((role, idx) => `#${idx + 1} ${role.title}`)
        .slice(0, 3)
        .join('\n');
    };

    const getTopExpertise = () => {
      const domainSkills = companyAnalysis?.required_skills?.domain
        ?.map(s => s.knowledge) || [];
      
      return domainSkills
        .slice(0, 3)
        .map((item, idx) => `#${idx + 1} ${item}`)
        .join('\n');
    };

    const getTopProviders = () => {
      if (!recommendedCourses) return [];

      // Create providers map with their best relevance scores
      const providersMap = new Map();
      recommendedCourses.forEach(course => {
        if (!course.educationProviderID) return;
        
        const currentProvider = providersMap.get(course.educationProviderID);
        const courseScore = course.relevance_score || 0;
        
        if (!currentProvider || courseScore > currentProvider.bestScore) {
          providersMap.set(course.educationProviderID, {
            id: course.educationProviderID,
            name: course.educationProviderName,
            logo: course.educationProviderLogo,
            bestScore: courseScore
          });
        }
      });

      return Array.from(providersMap.values())
        .sort((a, b) => b.bestScore - a.bestScore)
        .slice(0, 6)
        .map(({ id, name, logo }) => ({ id, name, logo }));
    };

    const generateCourseSections = () => {
      const sections = [];
      
      if (!recommendedCourses || !companyAnalysis?.required_skills) {
        return sections;
      }

      // Group courses by skill_matched and skill_category
      const coursesBySkill = {};
      
      recommendedCourses.forEach(course => {
        const key = `${course.skill_category}:${course.skill_matched}`;
        if (!coursesBySkill[key]) {
          // Find the skill description from required_skills based on category and skill name
          let skillDescription = '';
          let skillImportance = 'Nice to have';
          const category = course.skill_category?.toLowerCase();
          const skillName = course.skill_matched;

          let foundSkill = null;
          if (category === 'technical') {
            foundSkill = companyAnalysis.required_skills.technical?.find(
              s => s.skill.toLowerCase() === skillName.toLowerCase()
            );
          } else if (category === 'soft') {
            foundSkill = companyAnalysis.required_skills.soft?.find(
              s => s.skill.toLowerCase() === skillName.toLowerCase()
            );
          } else if (category === 'domain') {
            foundSkill = companyAnalysis.required_skills.domain?.find(
              s => s.knowledge.toLowerCase() === skillName.toLowerCase()
            );
          }

          skillDescription = foundSkill?.description || '';
          skillImportance = foundSkill?.importance || 'Nice to have';

          coursesBySkill[key] = {
            title: course.skill_matched,
            category: course.skill_category,
            courses: [],
            description: skillDescription,
            importance: skillImportance // Use the found skill's importance
          };
        }
        coursesBySkill[key].courses.push(course);
      });

      // Sort courses within each skill group by relevance score
      Object.values(coursesBySkill).forEach(skillGroup => {
        skillGroup.courses.sort((a, b) => (b.relevance_score || 0) - (a.relevance_score || 0));
      });

      // Convert to array and sort sections by skill importance
      const importancePriority = {
        'Critical': 3,
        'Important': 2,
        'Nice to have': 1
      };

      const sortedSections = Object.values(coursesBySkill)
        .sort((a, b) => {
          // First sort by importance
          const importanceDiff = (importancePriority[b.importance] || 0) - (importancePriority[a.importance] || 0);
          if (importanceDiff !== 0) return importanceDiff;
          
          // Then by highest course relevance score within each section
          const aMaxScore = Math.max(...a.courses.map(c => c.relevance_score || 0));
          const bMaxScore = Math.max(...b.courses.map(c => c.relevance_score || 0));
          return bMaxScore - aMaxScore;
        });

      // Convert sorted sections to final format
      sortedSections.forEach(({ title, category, courses, description }) => {
        const courseIds = courses
          .map(course => course.course_id)
          .filter(Boolean);
        
        if (courseIds.length > 0) {
          sections.push({
            title,
            description,
            competencyArea: category.toLowerCase() === 'domain' ? 'domain_expertise' : `${category.toLowerCase()}_skills`,
            courses: "${coursesFromIds}",
            courseIds
          });
        }
      });

      return sections;
    };

    // Helper function to get skill name from match reason
    const getSkillNameFromReason = (reason) => {
      // Extract the main skill from the match reason
      // This is a simple example - you might need to adjust based on actual data
      const firstSentence = reason.split('.')[0];
      return firstSentence.replace(/^Focuses on |^Related to /, '').trim();
    };

    // Helper function to determine competency area
    const determineCompetencyArea = (skillName, required_skills) => {
      if (required_skills?.technical?.some(s => skillName.toLowerCase().includes(s.skill.toLowerCase()))) {
        return "technical_skills";
      }
      if (required_skills?.soft?.some(s => skillName.toLowerCase().includes(s.skill.toLowerCase()))) {
        return "soft_skills";
      }
      if (required_skills?.domain?.some(s => skillName.toLowerCase().includes(s.knowledge.toLowerCase()))) {
        return "domain_expertise";
      }
      return "technical_skills"; // default
    };

    return {
      inlineTemplate: {
        title: `${companyData?.name || '[Placeholder] Company'} Learning Portal`,
        config: [
          {
            componentName: "LandingPageHeader",
            props: {
              fixed: true,
              transparent: true
            }
          },
          {
            componentName: "PartnerStatsHeroSection",
            props: {
              prefix: "Accelerate your career growth with tailored learning experiences",
              tickerOptions: ["Skills Development", "Career Growth", "Professional Learning"],
              partnerInfo: {
                name: companyData?.name || "[Placeholder] Company Name",
                logoUrl: companyData?.profile_pic_url || "[Placeholder] Default Company Logo URL",
                byLine: `Company · ${companyData?.hq?.city || '[Placeholder] Location'} · ${companyData?.company_size_on_linkedin || '[Placeholder] Company Size'} employees`
              },
              partnerStats: [
                {
                  title: "Top Job Roles",
                  body: getTopJobRoles()
                },
                {
                  title: "Top Skills",
                  body: getTopSkills()
                },
                {
                  title: "Top Expertise",
                  body: getTopExpertise()
                }
              ],
              background: {
                gradientIndex: 0
              }
            }
          },
          {
            componentName: "BannerWithTicker",
            props: {
              headline: `Next-Gen Skills and Courses to Accelerate ${companyData?.name || '[Placeholder] Company'} Growth`,
              description: companyAnalysis?.mission_statement || "[Placeholder] Company's mission and vision statement goes here",
              items: companyAnalysis?.key_specialities || [
                "[Placeholder] Key Specialty 1",
                "[Placeholder] Key Specialty 2",
                "[Placeholder] Key Specialty 3"
              ]
            }
          },
          {
            componentName: "TailoredCourses",
            props: {
              title: "Recommended Courses",
              description: `Start your journey today. With Mydra and ${companyData?.name}, the future is in your hands. Explore a tailored selection of courses below.`,
              courseSections: generateCourseSections()
            }
          },
          {
            componentName: "BenefitsOfChoosingMydra"
          },
          {
            componentName: "PaymentOptionsBanner",
            props: {
              paymentOptions: ["isa", "fundae", "learning_budget"]
            }
          },
          {
            componentName: "FAQ",
            props: {
              questionsAndAnswers: [
                {
                  question: "What financing options are available?",
                  answer: "Payment options depend on your circumstances and will be displayed at checkout."
                },
                {
                  question: "How can I learn more about a course?",
                  answer: "Our dedicated advisors are here to provide all the necessary details to help you make an informed decision."
                }
              ]
            }
          },
          {
            componentName: "TopProvidersSection",
            props: {
              headline: "Join the top training providers on Mydra today",
              providers: getTopProviders()
            }
          },
          {
            componentName: "Newsletter"
          }
        ],
        language: "en"
      },
      partner: {
        document: partnerId,
        documentType: "Company"
      },
      slug: companySlug,
      language: "en",
      isPublished: true,
      frequencyInHours: 0
    };
  };

  const handleViewLandingPages = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_BASE_URL}/api/companies/${partnerId}/landing-pages`, {
        headers: {
          'Content-Type': 'application/json',
          'X-Environment': 'Staging'
        }
      });

      console.log('Response status:', response.status);
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(`Failed to fetch landing pages: ${JSON.stringify(responseData)}`);
      }

      console.log("Company landing pages:", responseData);
      
      if (landingPageId) {
        window.open(`https://marketplace.staging.mydra.io/landing-pages/${landingPageId}`, '_blank');
      }
    } catch (err) {
      console.error('Error in handleViewLandingPages:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateLandingPage = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const data = generateLandingPageStructure();
      console.log('Sending data:', data);

      const response = await fetch(`${API_BASE_URL}/api/landing-pages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Environment': 'Staging'
        },
        body: JSON.stringify(data)
      });

      console.log('Response status:', response.status);
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(`Failed to create landing page: ${JSON.stringify(responseData)}`);
      }

      setLandingPageId(responseData._id);
      setSuccess(`Landing page created successfully! Preview URL: https://marketplace.staging.mydra.io/landing-pages/${responseData._id}`);
    } catch (err) {
      console.error('Error in handleCreateLandingPage:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateLandingPage = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const data = generateLandingPageStructure();
      console.log('Sending update data:', data);

      const response = await fetch(`${API_BASE_URL}/api/landing-pages/${landingPageId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Environment': 'Staging'
        },
        body: JSON.stringify(data)
      });

      console.log('Response status:', response.status);
      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(`Failed to update landing page: ${JSON.stringify(responseData)}`);
      }

      setSuccess(`Landing page updated successfully! Preview URL: https://marketplace.staging.mydra.io/landing-pages/${landingPageId}`);
    } catch (err) {
      console.error('Error in handleUpdateLandingPage:', err);
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper 
      elevation={0}
      sx={{ 
        mt: 4,
        p: 3,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" gutterBottom>
          Landing Page Preview Structure
        </Typography>
        
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        
        {success && (
          <Alert severity="success">{success}</Alert>
        )}

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>View Landing Page API Structure</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ 
              backgroundColor: 'grey.100', 
              p: 2, 
              borderRadius: 1,
              overflow: 'auto'
            }}>
              <pre style={{ margin: 0 }}>
                {JSON.stringify(generateLandingPageStructure(), null, 2)}
              </pre>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateLandingPage}
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Creating Landing Page...
              </>
            ) : (
              'Create Landing Page'
            )}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleViewLandingPages}
            disabled={loading || !landingPageId}
          >
            {loading ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Loading...
              </>
            ) : (
              'View Landing Page'
            )}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleUpdateLandingPage}
            disabled={loading || !landingPageId}
          >
            {loading ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Updating...
              </>
            ) : (
              'Update Landing Page'
            )}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default LandingPageData; 