import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 2,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
      },
    },
  },
  '& .MuiInputAdornment-root': {
    marginRight: theme.spacing(1),
  },
}));

const CompanyAnalysisForm = ({ onSubmit, disabled }) => {
  const [formData, setFormData] = useState({
    linkedin_url: '',
    company_url: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  // Check if both URLs are present and valid
  const isFormValid = formData.linkedin_url.trim() && formData.company_url.trim();

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography 
        variant="h5" 
        gutterBottom
        sx={{ 
          fontWeight: 600,
          color: 'primary.main',
          mb: 2,
        }}
      >
        Enter Company Details
      </Typography>
      
      <StyledTextField
        fullWidth
        required
        label="LinkedIn Company URL"
        value={formData.linkedin_url}
        onChange={(e) => setFormData({ ...formData, linkedin_url: e.target.value })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LinkedInIcon color="primary" sx={{ fontSize: 24 }} />
            </InputAdornment>
          ),
        }}
        placeholder="https://www.linkedin.com/company/..."
        sx={{ 
          '& .MuiOutlinedInput-root': {
            bgcolor: 'background.paper',
          }
        }}
      />

      <StyledTextField
        fullWidth
        required
        label="Company Website URL"
        value={formData.company_url}
        onChange={(e) => setFormData({ ...formData, company_url: e.target.value })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LanguageIcon sx={{ fontSize: 24, color: 'text.secondary' }} />
            </InputAdornment>
          ),
        }}
        placeholder="https://www.company.com"
        sx={{ 
          '& .MuiOutlinedInput-root': {
            bgcolor: 'background.paper',
          }
        }}
      />

      <Button
        type="submit"
        variant="contained"
        disabled={disabled || !isFormValid}
        startIcon={<SearchIcon />}
        sx={{ 
          mt: 2,
          py: 1.5,
          borderRadius: 3,
          textTransform: 'none',
          fontSize: '1.1rem',
          fontWeight: 500,
          boxShadow: 2,
          '&:hover': {
            boxShadow: 4,
            transform: 'translateY(-2px)',
          },
          transition: 'all 0.2s ease-in-out',
        }}
        fullWidth
      >
        {disabled ? 'Analyzing...' : 'Analyze Company'}
      </Button>
    </Box>
  );
};

export default CompanyAnalysisForm; 